.contract-list-page {

    & &-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px; }

    & &-card {
        margin: 0 10px 20px 10px;
        width: calc(25% - 20px);
        max-width: 100%; } }
