.vacancies-page {
  .vacancies-page-top {
    display: flex;
    margin-bottom: 8px;
    .vacancies-page-search {
      width: 300px;
      margin-right: 16px; }
    .vacancies-message {
      display: flex;
      align-items: center;
      margin-left: auto;

      .vacancies-message-block {
        display: flex;
        align-items: center;
        position: relative;

        .vacancies-message-text {
          margin: 0 5px 0 0;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: 0.3px;
          color: #12324F;
          cursor: pointer; }
        .vacancies-message-menu {
          position: absolute;
          background-color: #FFFFFF;
          width: 430px;
          top: 45px;
          right: -115px;
          box-shadow: 8px 7px 5px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          z-index: 5;

          .vacancies-message-menu-item {
            display: flex;
            align-items: center;
            padding: 10px 10px;
            border-radius: 8px;
            //margin: 8px 0
            cursor: pointer;
            transition: background-color 0.1s ease-in-out;
            &:nth-child(2) {
              padding: 5px 10px; }
            &:hover {
              background-color: #f5f5f5; }
            &:active {
              background-color: #f1f1f1; }

            .vacancies-message-menu-item-plug {
              width: 24px;
              height: 24px;
              min-width: 24px; }

            .vacancies-message-menu-item-text {
              margin-left: 10px; } } } }

      i {
        cursor: pointer; } }

    .fixed-buttons-right {
      display: flex;
      margin-left: 10px;

      .fixed-buttons-container {
        padding: 0; } } }
  .vacancies-page-body {
    margin: 8px auto 0px;

    .vacancies-page-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(465px, 1fr));
      grid-gap: 10px;
      justify-content: center; } } }

