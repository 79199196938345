.progress-checkbox {
    display: inline-flex;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: 4px solid #2F80ED;
    border-radius: 50%;
    position: relative;

    &-active {
        border-color : #F2994A; }

    &-check {
        position: absolute;
        top: -11px;
        font-size: 30px;
        left: -3px;
        color: #12324F; } }
