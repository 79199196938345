.about-me-page-wrapper {
    background: white;

    .about-me {
        border: none;

        .about-me-list {
            list-style: none;
            margin: 0;
            padding: 0;

            .about-me-item:not(:last-of-type) {
                margin-bottom: 26px; }

            .about-me-item {
                font-weight: normal;

                .job-status {
                    display: flex;
                    align-items: center; }

                .job-status-circle {
                    display: inline-block;
                    margin-right: 10px;
                    width: 12px;
                    height: 12px;
                    background-color: #00E23F;
                    border-radius: 100%; } } } } }
