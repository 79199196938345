.organization-authorization-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  .consent_to_processing {
    display: flex;
    margin: 5px 0 8px;
    span {
      margin: auto 0; } }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;

    .container-body {
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      padding: 15px 20px;
      width: 80%;

      .container-body-field {

        .container-body-field-head {
          display: flex;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          margin: 0px 0 12px; }

        .container-body-field-input {
          background: #FFFFFF;
          border: 2px solid #12324F;
          border-radius: 2px;
          width: 100%;
          height: 44px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          padding: 0 10px;

          div {
            border: none; } }

        .container-body-field-error {
          min-height: 15px;
          padding: 2px 5px;
          color: #ED0000;
          font-size: 13px;
          line-height: 14px; }

        .required-field {
          margin-right: 5px;
          color: #ED0000; } }

      > .message {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        height: 6rem; }

      > input {
        margin-bottom: 1rem;
        width: 100%; } }

    .container-body-code {
      display: flex;
      flex-direction: column;
      width: 60% !important;

      > .message {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        height: 6rem; }

      > input {
        margin-bottom: 1rem;
        width: 100%; } }

    > .footer-message {
      margin-top: 1rem;
      // height: 2rem
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .span-buttons {
        margin-left: 0 !important;
        justify-content: center !important; }

      .back-to-auth-btn,
      .support {
        margin-top: 20px; }

      .consent_to_processing {
        display: flex;

        span {
          margin: auto 0; } }

      .support {
        font-weight: bold; } } } }
