.vacancy-card-preview {
  .vacancy-card-preview-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 540px;
    height: 80px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #12324F;
    padding: 20px 0 0 0;
    margin: 0 34px 0 34px;
    border-bottom: 1px solid black;
    i {
      right: -21px;
      top: 11px;
      position: absolute;
      cursor: pointer;
      margin-bottom: auto; } }
  .vacancy-card-preview-view {
    display: flex;
    flex-direction: column;
    padding: 34px 34px 20px;
    .vacancy-card-preview-view-wrapper {
      width: 549px;
      min-width: 549px;
      &:nth-child(2) {
        padding: 34px 0; }
      .vacancy-card-preview-view-headline {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #12324F;
        padding-bottom: 10px; }
      .vacancy-card-preview-view-item {
        padding-bottom: 16px;
        &:last-child {
          padding-bottom: 0; }
        .vacancy-card-preview-view-title {
          padding-bottom: 4px;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.3px;
          color: #5C6E8C; }
        .vacancy-card-preview-view-value {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          color: #12324F;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word; } } } } }
