.avatar-container {
  display: flex;
  position: relative;

  > i {
    cursor: pointer;
    font-size: x-large; }

  > .cancel {
    position: absolute;
    right: 0;
    top: 0; }

  .image-input {
    width: 310px;
    height: 176px;
    position: relative;
    border: 2px solid var(--mdc-theme-text-secondary-on-background);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center; }

  > * {

    font-size: xx-large; } }


.add-photo {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
