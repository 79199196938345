.page-title-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .title-wrapper-b {
    display: flex;
    align-items: center;
    max-width: 900px;
    min-width: 400px;
    flex-wrap: wrap;
    flex-grow: 1;

    i {
      padding: .3rem; }

    .title {
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: .3rem;
      margin-right: 1rem; }

    a {
      all: unset; }

    .children {
      display: flex;
      align-items: center !important;
      flex: 1 1 0; }

    .crumb-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.3px;
      color: #12324F; }
    .crumb-title-active {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.3px;
      color: #12324F; } }

  > .title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    max-width: 1000px;
    min-width: 400px;
    flex: 1 1 0;

    i {
      padding: .3rem; }

    .title {
      display: flex;
      overflow: hidden;
      text-overflow: elepsis;
      margin-left: .3rem;
      margin-right: 1rem; }

    a {
      all: unset; }

    .children {
      display: flex;
      align-items: center !important;
      flex: 1 1 0; } } }
