.worker-request-list {
    & &-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .page-title-component {
            margin-bottom: 0; } }

    & &-message-card {
        display: flex;
        align-items: center;

        &-icon {
            margin-right: 13px; }

        &-toggle-button {
            font-size: 30px;
            cursor: pointer;
            opacity: 0.5;

            &-active {
                opacity: 1; } } }

    &-find-input {
        margin-bottom: 20px; }

    & &-request-title {
        font-weight: 600;
        font-size: 20px;
        margin: 15px 5px; }

    & &-profile-info {
        display: flex;
        margin-bottom: 24px; }

    & .profile-filling {
        width: auto;
        margin-bottom: 30px;

        &-title {
            font-weight: 500;
            font-size: 16px;
            max-width: 300px;
            margin-bottom: 20px;

            &-new-actions {
                margin-top: 0;
                margin-bottom: 10px; } } }

    & .profile-filling-card-list {
        display: flex;
        grid-gap: 12px;
        cursor: pointer;
        margin-right: 24px; }

    &-profile-info-card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        transition: box-shadow 0.2s;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: white;
        padding: 20px;
        width: 192px;
        border-radius: 2px;
        text-align: center;
        flex: auto;
        height: 100%;

        &:hover {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); } } }
