.profile-page {
    min-width: 520px;
    .profile-page-wrapper {
        position: relative;
        .user-authorization-number {
            position: absolute;
            bottom: 5rem;
            right: 1.6rem;
            color: var(--mdc-theme-text-secondary-on-light);
            font-weight: 400;
            text-align: right; }
        .title-wrapper {
            min-width: 100%;
 }            // justify-content: center
        .page-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            text-overflow: elepsis;
            // margin-left: .3rem
            // margin-right: 1rem
            font-size: 1.5rem;
            font-weight: 700; } } }

