.request-filter-dialog {
    .mdc-dialog__container {
        width: 100%; }

    .mdc-dialog__surface {
        max-width: 790px !important;
        width: 100%; }

    & &-field {
        margin-bottom: 15px; }

    & &-salary-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 26px; }

    & &-row-field {
        display: flex;
        flex: 1 1 auto;

        &:last-child {
            margin-left: 16px; }

        &-title {
            margin-right: 16px;
            position: relative;
            bottom: 5px; } }

    & &-actions {
        display: flex;
        grid-gap: 16px; } }
