.document-worker-wrapper {
  flex: 0 0 212px;
  margin: 0 12px 10px 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  border: 2px solid #E1E1E7;
  border-radius: 2px;
  display: flex;

  .document-worker-inner {
    padding: 24px;
    width: 100%;

    .document-worker-text {
      display: inline-block;
      margin: 0;
      font-weight: 500;
      margin-bottom: 8px; }

    .certificate-number {
      margin-bottom: 12px; }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .title {
        margin-bottom: 5px; }

      .issue-date {
        font-weight: 500;
        color: #12324F;
        margin-bottom: 12px; } }

    .img-wrapper {
      position: relative;
      width: 160px;
      height: 160px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid var(--mdc-theme-text-secondary-on-light);
      cursor: pointer;
      margin-left: -1px; }

    .img-wrapper:hover > .preview-loupe {
      display: flex; }

    .preview-loupe {
      display: none;
      position: absolute;
      font-size: 50px;
      color: white;
      top: 0;
      right: -1px;
      bottom: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.35); } } }


