.mobilize-subpage {

    .link-to-contract {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: var(--mdc-theme-text-primary);
        font-weight: 500;
        text-decoration: none;

        .text-wrapper {
            display: flex;
            align-items: center;

            .icon {
                font-size: 30px;
                margin-right: 5px; } } }


    .save-status {
        display: none;
        align-items: center;
        position: fixed;
        top: 76px;
        right: 1rem;
        font-size: 25px;
        color: #12324F;
        z-index: 9999;
        // opacity: 1
        // transition: opacity 0.3s ease-in-out

        .save-status-icon {
            padding-top: 5px;
            margin-right: 0.5rem;
            color: green; } }

    .mobilize-checkbox-list-wrapper {

        .mobilize-checkbox-list {
            list-style: none;
            padding: 0;
            .off-checkbox {
                .mdc-checkbox__background {
                    background-color: #Ffffff !important; } }

            .row.headings {
                height: 51px; }

            .row {
                display: grid;
                grid-template-columns: 145px 145px 1fr;
                height: auto;
                background: white;
                border-bottom: 0.5px solid #9AA2B0;

                .cell {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .input {
                        display: flex;
                        width: 100%; } }

                .cell.checkbox {
                    color: red; }

                .cell.comments {
                    padding: 1.5rem;
                    flex-direction: column;
                    align-items: flex-start;

                    .title {
                        margin-bottom: 0.5rem; } }

                .cell:not(:last-of-type) {
                    border-right: 0.5px solid #9AA2B0; }

                .heading {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px; } } } } }

