#topbar {
  background-color: var(--mdc-theme-secondary);
  color: var(--mdc-theme-primary);

  > .mdc-top-app-bar__row {
    height: auto;

    > section {
      display: flex;
      margin-right: 1rem;
      min-width: auto !important;

      > .info-block {
        display: flex;
        margin-right: 1rem;

        > .avatar-user {
          margin-right: 16px; }

        > .user-info {
          display: flex;
          flex-direction: column;
          text-align: right;
          justify-content: center;
          padding-left: 10px;
          padding-right: 10px;

          > .name {
            font-weight: 700; } }
        .org-info-block {
          display: flex;
          padding-left: 10px;
          padding-right: 10px; }

        > .org-info {
          display: flex;
          align-items: center;
          .top-avatar {
            height: 44px;
            width: 44px;
            margin-right: .8rem;

            > i {
              font-size: 44px; } }

          > .block {
            display: flex;
            flex-direction: column;
            justify-content: center;

            > .name {
              font-weight: 700; } } } }


      > .logout {
        margin-inline: 10px; }

      > button {
        color: var(--mdc-theme-primary);
        background-color: rgba(18, 50, 79, 0.08); } } }
  .topbar-active-block {
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 19;
    background-color: #Ffffff; }
  .topbar-link {
    color: #12324f;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: flex-end;
    height: 25px;
    max-height: 25px;
    i {
      margin-right: 6px; }
    div {
      text-decoration: underline; } }
  .react-select-component {
    &.react-select-component--invalid {
      > .select__control {
        border-color: var(--mdc-theme-error);

        &:hover {
          border: none !important;
          border-color: var(--mdc-theme-error); }

        &.select__control--is-focused {
          border: none !important;
          // border-color: var(--mdc-theme-error)
          box-shadow: 0 0 0 1px var(--mdc-theme-error); } } }

    > .select__control {
      min-height: 30px;
      // border-color: #9E9E9E
      border: none !important;
      background-color: transparent;
      outline: none;

      .select__value-container {
        justify-content: flex-end; }

      &:hover {
        cursor: pointer;
        border: none !important;
        border-color: #373737; }

      &.select__control--is-focused {
        border: none !important;
        box-shadow: none !important; }

      > .select__indicators {
        > .select__indicator {
          color: #9E9E9E;
          padding: 5px 5px 5px 0px;

          &:hover {
            color: #373737; } }

        > .select__indicator-separator {
          display: none !important;
          background-color: #9E9E9E;
          padding-block: 6px; } }

      > .select__value-container {
        padding-inline: 12px;
        font-family: Roboto, sans-serif; } }

    > .select__menu {
      > .select__menu-list {
        > .select__option {
          padding: 11.5px 12px;

          &:hover {
            background-color: #F5F5F5; }

          &.select__option--is-focused {
            background-color: #F5F5F5; }

          &.select__option--is-selected {
            background-color: #E1E1E1;
            color: black; } } } } } }

.role-select {
  min-width: 200px; }
