.employment-process-page {

    &-employment-card {
        background-color: #fff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        padding: 36px 32px;

        &-item {
            display: flex;
            align-items: center;

            &:first-child {
                padding-top: 0; }

            &:last-child {
                padding-bottom: 0; }

            &:last-child {
                border-bottom: none; } } } }
