.drag_and_drop_cells {
  min-width: auto;
  display: flex;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); }

.drag_and_drop {
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
  text-overflow: ellipsis !important;
  .dialog-filter-last {
    right: 0; }
  &:last-child {
    .dialog-filter {
      right: 0; } }
  &:first-child {
    .dialog-filter {
      right: -51px; } }
  .table-cell-inner {
    display: flex;
    min-width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis !important;
    overflow: hidden !important; }

  &.devider:not(:last-of-type) {
    border-right: 0.5px solid #DEE7EF; }

  .drag_and_drop-inner {
    display: flex;
    text-align: left;
    .name {}

    .expands {
      display: flex;
      flex-direction: column;
      margin-right: 5px;

      .expand {
        &:first-child {
          margin-bottom: 10px; }
        cursor: pointer;
        height: 20px; }

      .expand-active {
        color: red; } }

    .icons {
      display: flex;
      justify-content: space-between;
      width: 60px;
      margin-left: auto;

      .plug {
        width: 24px;
        height: 24px; }

      .filter_alt {
        color: red; }

      .more_horiz {
        cursor: pointer; } } } }

.drag_and_drop_active {
  background-color: #F2F5F9; }
