.circular-filling-progress {
    position: relative;

    & &-circle-wrapper {
        fill: none;
        transform: rotate(-90deg) scale(1, -1); }

    & &-circle {
        fill: none;
        stroke: #CCDEF1; }

    & &-line {
        border: 1px solid red;
        fill: none;
        stroke: #2F80ED; }

    & &-text {
        position: absolute;
        font-size: 12px;
        color: #000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
