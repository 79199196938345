.gray {
    background: #F5F5F8; }
.bold {
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3px; }
.text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
.need-wrapper:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); }
.need-wrapper {
    transition: all 0.2s ease-in-out;
    background: white;
    padding: 20px;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    .need-end-of-publication {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.3px;
        color: #5C6E8C;
        margin-bottom: 20px; }

    .need-params-wrapper {
        display: grid;
        height: 158px;
        grid-gap: 7px;
        grid-template-columns: 30% calc(70% - 7px);
        grid-template-rows: 50% calc(50% - 3.5px);
        margin-bottom: 20px;
        overflow: hidden !important;
        .need-detail {
            padding: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            border-radius: 2px; } }

    .need-row {
        margin-bottom: 20px;
        // padding: 20px 0px 10px
        font-weight: 400; }
    .fill-progress {
        .title {
            margin-bottom: 8px;
            color: #5C6E8C;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.3px; }
        .mdc-linear-progress {
            background: #DEE7EF; }
        .mdc-linear-progress__buffering-dots {
            display: none; }
        .mdc-linear-progress__bar-inner {
            border-radius: 5px;
            border-color: #2F80ED !important; } }

    .response-statuses-wrapper {
        display: flex;
        flex-direction: column;
        .response-status {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            border-radius: 2px; }
        .response-status:nth-of-type(odd) {
            display: flex;
            justify-content: space-between;
            background: #F5F5F8 !important; } } }
