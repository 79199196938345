.form-field-wrapper {
    display: inline-flex;
    flex-direction: column;

    .form-field-error {
        color: #b00020;
        font-size: 14px;
        max-width: inherit; }

    .form-field-required {
        color: #b00020;
        font-size: 14px; }

    &-full-width {
        width: 100%;
        max-width: inherit; } }
