.fullpage-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    padding: 2rem;

    .back.rmwc-icon {
            position: absolute;
            top: 5px;
            left: 5px;
            font-size: 24px !important;
            align-self: flex-start;
            cursor: pointer; }

    > .rmwc-icon {
        font-size: 150px; } }

