.who-worker-write {
  height: 84%;
  position: relative;

  .who-worker-write-module-blocked {
    pointer-events: none; }

  .who-worker-write-module-blocked:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0px;
    background: transparent;
    background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.51), #ffffff);
    z-index: 2;
    pointer-events: auto; }

  .who-worker-write-inner {
    display: flex;
    flex-direction: column;
    height: 100%;

    .who-worker-write-settings {
      display: flex;
      flex-wrap: wrap;

      .who-worker-write-item {
        flex: 0 1 50%;

        .who-worker-write-item-inner {
          padding: 10px;

          .who-worker-write-item-header {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.3px;
            color: #12324F;
            margin-bottom: 10px; }

          .who-worker-write-item-select {
            width: 450px;
            margin-right: 40px; } } } }

    .who-worker-write-button {
      margin-top: auto; } } }
