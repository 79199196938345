.avatar-editor {
  display: flex;
  flex-direction: column;
  min-height: 615px;
  width: 821px;

  .avatar-editor-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    min-height: 90px;
    width: 100%;
    background: #DEE7EF;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px; }

  .avatar-editor-headline {
    display: flex;
    align-items: center;
    justify-content: center; }

  .avatar-editor-controls {
    padding: 10px;
    margin: auto;

    .avatar-editor-select {
      .avatar-editor-input {
        position: absolute;
        z-index: 10;
        opacity: 0;
        height: 44px;
        width: 185px;
        cursor: pointer; }

      .avatar-editor-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .mdc-button__label {
          display: flex;

          .avatar-editor-btn-text {
            padding: 5px 0px 5px 5px; } } } } }

  .avatar-editor-avatars {
    display: flex;
    margin: 0 auto;

    .ReactCrop {
      width: 100%;
      height: 100%;
      margin-right: 15px;

      img {
        max-height: 535px;
        max-width: 400px; } } } }

@media (max-width: 1246px), (max-height: 720px) {
  .avatar-editor {
    display: flex;
    flex-direction: column;
    min-height: 615px;
    width: 821px;

    .avatar-editor-text {
      font-size: 20px; }

    h5 {
      font-size: 20px;

      .avatar-editor-controls {
        padding: 10px;
        margin: auto;

        .avatar-editor-select {
          .avatar-editor-input {
            position: absolute;
            z-index: 10;
            opacity: 0;
            height: 44px;
            width: 185px;
            cursor: pointer; }

          .avatar-editor-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .mdc-button__label {
              display: flex;

              .avatar-editor-btn-text {
                padding: 5px 0px 5px 5px; } } } } } } } }

