.professional-exp-subpage-wrapper {
  background: white;
  position: relative;
  padding: 32px;

  .buttons-edit {
    position: absolute;
    right: 24px;
    top: 24px; }

  .profession-info-wrapper {

    .profession-info-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .profession-info-item:not(:last-of-type) {
        margin-bottom: 1.5rem; }

      .profession-info-item {
        font-weight: normal;


        .additional-professions-list {
          list-style: none;
          margin: 0;
          padding: 0;
          font-weight: 500; }

        .additional-profession:not(:last-of-type) {
          margin-bottom: 8px; } } } }

  .allowance-text {
    margin: 0;
    margin-bottom: 8px; }

  .experience-wrapper {
    border-bottom: none;

    .title {
      margin: 0;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.3px;
      color: #12324F; }

    .experience-card-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;

      .experience:not(:nth-of-type(even)) {
        margin-right: 12px; }

      .experience:nth-of-type(n+3) {
        margin-top: 15px; }

      .experience {
        flex: 0 0 calc(50% - 6px);
        min-width: 300px;
        margin: 0;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border: 2px solid #E1E1E7;

        .experience-inner {
          padding: 24px;
          width: 100%;

          .experience-list {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            list-style: none;

            .experience-item:not(:last-of-type) {
              margin-bottom: 1rem; } } } } } } }

.section {
  margin-top: 16px; }
