.experience-form-wrapper {
  padding: 12px;
  position: relative;
  background: #FFFFFF;

  .experience-form-buttons-save {
    display: flex;
    position: absolute;
    right: -10;
    top: -10;

    .button-save {
      margin-right: 42px; } } }

.experience-form {
  display: flex;
  column-gap: 97px !important; }

.button-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .button-form-value {
    display: flex; }

  .button-form-title {
    margin: 0; } }

.experience-form-subProfession {
  margin: 16px 0 16px; }


.experience-form-number-field {
  padding: 12px;
  width: 100%; }

.experience-form-image {
  width: 208px;
  height: 208px;
  border: 2px solid #E1E1E7;
  padding: 24px;
  border-radius: 2px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

  .image-input {
    width: 160px;
    height: 160px;
    background: #F5F5F8;
    border: 2px solid #E1E1E7; } }

.experience-form-field-item {
  margin-bottom: 16px; }

.experience-form-field-title {
  margin-top: 10px; }

.experience-checkbox-title {
  margin: 24px 0 16px; }

.experience-checkbox {
  display: flex;
  flex-direction: row;
  margin-left: -11px; }

.experience-checkboxes-field {
  display: flex; }

.experience-checkboxes-block {
  display: flex;
  align-items: center; }
.experience-checkboxes-block-input {
  width: 114px;
  height: 34px;
  text-align: center; }

.experience-checkbox-after {
  margin: 0 16px 0 10px; }
