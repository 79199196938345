.zoomed-img-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    z-index: 9999;
    // pointer-events: none

    .zoomed-img-inner {
        position: relative;
        width: 80%;
        height: 80%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat; }

    .close-zoomed-preview {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
        font-size: 45px;
        color: white;
        background-color: rgba(0,0,0,0.4);
        cursor: pointer; } }
