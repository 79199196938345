.no-padding {
    padding: 0px !important; }
.settings-page {
    display: flex;
    overflow: hidden;
    .settings-module-blocked {
        pointer-events: none; }
    .settings-module-blockedm {
        pointer-events: none; }
    .settings-module-blockedm:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0px;
        cursor: default;
        background: transparent;
        background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.51), #ffffff);
        margin-top: 480px;
        z-index: 2;
        pointer-events: auto; }
    .side-bar-wrapper {
        transition: width 0.3s ease-in-out;
        width: 256px;
        padding: 16px;
        background: #5C6E8C;
        color: white;
        position: fixed;
        height: 100%;
        z-index: 3;

        .show-icon {
            margin-left: auto;
            width: 24px;
            cursor: pointer; }
        .show-icon-show {
            padding-left: 12px;
            margin-left: 0; }
        .nav-section {
            padding: 0px 0px 20px 0px;
            border-bottom: 0.5px solid #E1E1E7;
            .nav-section-head {
                display: flex;
                align-items: center;
                margin-bottom: 1rem; }
            .nav-section-head-show {
                padding-left: 8px; }
            .nav-section-title {
                display: flex;
                align-items: center;
                padding: 11px 8px;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.2px !important;
                color: #F5F5F8;
                overflow: hidden; }
            .nav-section-title-show {
                display: none; }
            .nav-item-link {
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 11px 20px 11px 20px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #F5F5F8;
                &.active {
                    background: white;
                    color: var(--mdc-theme-primary);
                    .nav-item-link-number {
                        border: 2px solid #12324F; } }
                .nav-item-link-number {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 50%;
                    border: 2px solid #F5F5F8;
                    text-align: center;
                    margin-right: 5px; }
                .nav-item-title {
                    //transition: overflow 0.3s ease-in-out
                    overflow: hidden; }
                .nav-item-title-show {
                    display: none; } }
            .nav-item-link-show {
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 11px 10px 11px 10px; } }
        .nav-section:not(:first-of-type) {
            padding: 20px 0px; } }

    .side-bar-wrapper-show {
        width: 61px;
        padding: 16px 10px;
        background: #5C6E8C;
        color: white;
        position: fixed;
        height: 100%; }

    .content-wrapper {
        transition: margin-left 0.3s ease-in-out;
        padding: 20px;
        width: 100%;
        margin-left: 256px;
        overflow: auto;
        .content-inner {
            padding: 20px;
            min-width: 800px;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1) !important;
            background: white;
            overflow: auto;
            .page-title-component {
                padding-bottom: 20px;
                margin: 0px; } }
        .content-inner-show {
            padding: 0px;
            box-shadow: none !important;
            background: none; }
        .title,
        .icon {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 28px;
            letter-spacing: 0.3px; }

        .icon {
            position: relative;
            font-weight: 500; }
        .description {
            box-shadow: none !important;
            margin-bottom: 15px;

            .inner {
                padding: 15px;
                background: #DEE7EF;
                display: flex;
                flex-direction: column;
                .description-title {
                    padding-left: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    letter-spacing: 0.3px;
                    margin-bottom: 10px; }
                .row-wrapper:not(:last-of-type) {
                    margin-bottom: 10px; }
                .row-wrapper {
                    display: grid;
                    align-items: center;
                    justify-content: start;
                    grid-template-columns: 45px 1fr;

                    .mdc-checkbox {
                        padding-top: 5px; }
                    .mdc-checkbox--disabled {
                        .mdc-checkbox__background {
                            border-color: #9AA2B0 !important;
                            background: #9AA2B0 !important; } }
                    .required-default {
                        .mdc-checkbox__checkmark {
                            display: none; }
                        .mdc-checkbox__background::after {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            content: "*";
                            color: white;
                            top: 9.5px;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            font-size: 32px;
                            font-weight: 700; } } } } } }
    .content-wrapper-show {
        padding: 20px;
        width: 100%;
        margin-left: 61px;
        overflow: auto; } }
