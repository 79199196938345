.vacancy-page {
  .vacancy-page-top {
    padding: 0 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 13px;

    .vacancy-page-top-extend {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 68px;
      padding: 12px 0;

      .vacancy-page-top-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #12324F; }

      .vacancy-page-top-btn {
        margin-right: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        text-decoration-line: underline;
        color: #12324F;
        cursor: pointer; } }

    .vacancy-page-top-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 68px;
      padding: 12px 0;

      .vacancy-page-top-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #5C6E8C; }

      .vacancy-page-top-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #12324F; } } }

  .vacancy-page-body-redact {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 40px;
    .vacancy-page-body-redact-container {
      width: 550px;

      .vacancy-page-body-redact-block {
        padding-bottom: 33px;

        .vacancy-page-body-redact-block-headline {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.3px;
          color: #12324F;
          padding: 14px 0 12px; }

        .vacancy-page-body-redact-item {
          .vacancy-page-body-redact-title {
            display: flex;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.3px;
            color: #5C6E8C;
            padding-bottom: 4px;

            div {
              color: #FF0000;
              padding-left: 2px; } }

          .vacancy-page-body-redact-textfield {
            width: 100%;
            background: #FFFFFF;

            textarea {
              margin: 0; } }

          .vacancy-page-body-redact-select {
            width: 100%;
            background: #FFFFFF;
            .vacancy-page-body-redact-select-option {
              height: 61px;
              padding: 5px 16px;
              display: flex;
              flex-direction: column;
              cursor: default;
              &:hover {
                background-color: #DEEBFE; }
              .vacancy-page-body-redact-select-option-headline {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.3px;
                color: #12324F;
                padding-bottom: 3px; }
              .vacancy-page-body-redact-select-option-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.3px;
                color: #9AA2B0; } }
            .select__control {
              padding: 3px; } }

          .vacancy-page-body-redact-dates {
            .vacancy-page-body-redact-dates-wrapper {
              display: flex;
              justify-content: space-between;
              .vacancy-page-body-redact-date-wrapper {
                //padding: 0 16px
                width: 271px; }

              .vacancy-page-body-redact-date {
                width: 271px;
                height: 44px;
                padding: 0 16px;
                font-size: 16px; } } }

          .vacancy-page-body-redact-check {
            display: flex;
            align-items: center;

            .off-checkbox {
              .mdc-checkbox__background {
                background-color: #Ffffff !important; } }

            .vacancy-page-body-redact-check-text {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.3px;
              color: #12324F; } }

          .vacancy-page-body-redact-error {
            color: #FF0000;
            font-size: 13px;
            height: 22px;
            padding-left: 2px; } } } } }

  .vacancy-page-body-view {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;

    .vacancy-page-body-view-wrapper {
      width: 549px;
      min-width: 549px;
      &:nth-child(2) {
        padding: 55px 0; }

      .vacancy-page-body-view-headline {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #12324F;
        padding-bottom: 20px; }
      .vacancy-page-body-view-item {
        padding-bottom: 16px;
        &:last-child {
          padding-bottom: 0; }
        .vacancy-page-body-view-title {
          padding-bottom: 4px;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.3px;
          color: #5C6E8C; }
        .vacancy-page-body-view-value {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          color: #12324F;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word; } } } }
  .vacancy-page-bottom {
    width: 100%;
    .buttons {
      display: flex;
      flex-direction: row;
      margin-right: auto;
      button {
        flex: 0 0 283px; } } } }
