.messages-page {
  .screen-locked {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: auto 0;

    .screen-locked-text {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px; } }

  .messages-page-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .messages-page-checkbox {
      display: flex;
      align-items: center;

      .messages-page-checkbox-text {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.3px;
        color: #12324F; } } }

  .messages-page-list {
    padding: 0 20px;

    .messages-page-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 95px;
      border-bottom: 1px solid #E1E1E7;
      cursor: pointer;

      &:last-child {
        border: none; }

      .messages-page-item-avatar {
        background-color: #D9D9D9;
        width: 64px;
        height: 64px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 50%; }

      .messages-page-item-notification {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: red;
        top: -3px;
        left: -3px;
        color: #Ffffff;
        font-size: 14px; }

      .messages-page-text {
        margin-left: 40px;
        flex: 1 1 40%;
        overflow: hidden;

        .messages-page-item-fullName {
          color: #12324F;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 5px; }

        .messages-page-item-last-message {
          color: #5C6E8C;
          height: 18px;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          //max-width: 50%
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; } }

      .messages-page-item-more {
        padding: 30px; }

      .messages-page-menu-body {
        position: absolute;
        min-width: 160px;
        width: 175px;
        min-height: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        border-radius: 8px;
        top: 0px;
        right: 60px;
        z-index: 3;

        .messages-page-menu-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .messages-page-menu-item {
            display: flex;
            cursor: pointer;
            align-items: center;
            width: 100%;
            border-radius: 8px;
            padding: 5px 15px 8px 15px;
            transition: background-color 0.1s ease-in-out;

            &:hover {
              background-color: #f5f5f5; } } } } } } }


