.edit-user-page {
  .edit-user-page-roles-settings {
    margin-top: 15px;
    .lock {
      padding: 8px; }
    .edit-user-page-roles-header {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #5C6E8C;
      margin-bottom: 15px; }

    .edit-user-page-roles {
      display: flex;
      flex-wrap: wrap;

      .edit-user-page-role {
        display: flex;
        align-items: center;
        flex: 0 1 50%;
        margin-bottom: 15px; }

      .edit-user-page-role-off {
        opacity: 0.4;

        a {
          padding: 0 5px; } } } }

  .form-component {
    overflow: hidden; }

  .caution-msgs-wrapper {
    background: white;
    padding: 0 20px 20px 20px;

    .caution-msgs-inner {
      background: #DEE7EF;
      padding: 16px;

      .title,
      .msg:not(:last-of-type) {
        margin-bottom: 16px; }

      .msg {
        display: flex;

        .icon {
          margin-right: 10px; }

        .error {
          color: #ED0000; } } } } }
