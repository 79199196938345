.system_settings-page {
  .system_settings-buttons {
    display: flex;
    justify-content: end;
    position: relative;
    width: 150px;
    margin-left: auto; }

  .system_settings-container {
    background-color: #FFFFFF;
    padding: 40px 20px;
    margin-top: 24px;

    .system_settings-description {
      display: flex;
      padding-bottom: 25px;

      i {
        color: #ED0000;
        margin-right: 10px; }

      .system_settings-text {
        width: 510px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #12324F; } }
    .system_settings-title {
      padding-bottom: 20px;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      color: #2B3648; }
    .system_settings-field {
      display: flex;
      align-items: center;
      .system_settings-checkbox {}
      .system_settings-label {
        padding: 0px 8px; }
      i {
        cursor: pointer;
        color: #5C6E8C; }
      .system_settings-text {
        padding: 20px 0;
        margin: 0 9px; }
      .system_settings-select {
        width: 200px; } } } }
