.review-comment-form {

    &-rating {
        margin-bottom: 16px;

        &-error {
            margin-top: 3px;
            color: #b00020;
            font-size: 14px;
            max-width: inherit; } }

    &-star {
        font-size: 30px;

        &--empty {
            color: #D1D1D4; } }

    &-title {
        font-weight: 600;
        font-size: 16px;
        color: #12324F;
        margin-bottom: 16px; }

    &-textarea {
        width: 100%;
        margin-bottom: 16px;

        .mdc-text-field__resizer {
            resize: vertical; } }

    &-button-save {
        margin-right: 16px; } }
