.instruction-component {
  position: fixed;
  display: flex;
  z-index: 7;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .instruction-dialog {
    z-index: 9;
    background-color: #Ffffff;

    .instruction-dialog-title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 564px;
      height: 90px;
      background: #DEE7EF;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #12324F; }

    .instruction-dialog-content {
      width: 564px;
      padding: 30px 30px 20px 30px;

      .instruction-step {

        .instruction-step-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          color: #12324F; }

        .instruction-step-list {
          padding-inline-start: 20px;

          .instruction-step-list-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #12324F;
            padding-bottom: 8px; } }

        .instruction-check {
          .instruction-check-title {
            padding-top: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #5C6E8C; }
          .instruction-check-variants {
            display: flex;
            justify-content: center;
            .instruction-check-variant {
              display: flex;
              padding-top: 16px;
              margin: 0 10px;
              .instruction-check-radio {}
              .instruction-check-radio-label {
                margin-left: 16px; } } } } } }


    .instruction-dialog-btns {
      display: flex;
      height: 76px;
      align-items: center;
      justify-content: space-evenly;
      border-top: 1px solid;

      .instruction-dialog-btn-underlined {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #12324F; }

      .instruction-dialog-btn {
        cursor: pointer;
        text-align: center;
        width: 210px;
        height: 27px;
        padding-top: 5px;
        background-color: #12324F;
        border-radius: 2px;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.3px;
        color: #F5F5F8; } } }

  .instruction-dialog-scrim {
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.32); } }
//opacity: 1;
