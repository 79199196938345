.worker-response-statistics {
    display: flex;
    grid-gap: 12px;
    cursor: pointer;

    &-card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        transition: box-shadow 0.2s;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: white;
        padding: 28px 20px;
        width: 192px;
        border-radius: 2px;
        text-align: center;
        flex: auto;
        height: 100%;

        &:hover {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); } }

    &-title {
        color: #5C6E8C;
        font-size: 14px;
        font-weight: 500;
        max-width: 80px;
        margin: 0 auto;
        line-height: 16px; }

    &-value {
        color: #2F80ED;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 4px; } }
