.form-component {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .form-block-header {
    display: flex;
    position: relative;
    padding: 1.6rem;
    .form-block-header-name {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      color: #12324F; }
    .form-block-header-icon {
      margin: auto 0; }
    .form-block-header-buttons {
      display: flex;
      margin-right: 30px;
      margin-left: auto;
      .form-block-header-button {
        margin: 0 10px; } }
    .expand_more {
      position: absolute;
      right: 25px;
      top: 25px;
      cursor: pointer; } }

  &.form-component--solid {
    background-color: white;
    padding: 1.6rem;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12);

    > .form-block {
      background-color: unset;
      box-shadow: unset;
      padding: unset;
      &:nth-child(5) {
        background-color: #F5F5F8;
        padding: 20px; }
      &:not(:last-child) {
        > .form-column {
          margin-bottom: unset; } } } }

  .mdc-list-divider {
    margin-block: 10px; }

  .form-block {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.6rem;
    row-gap: 10px;
    padding: 1.6rem;

    > .form-column {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      max-width: 100%;

      &.form-column--fullwidth {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > .form-column--item {
          position: relative;
          width: unset !important; } }

      > .form-column--title {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;

        > .form-column--caption {
          font-size: smaller;
          color: var(--mdc-theme-text-secondary-on-light);
          white-space: break-spaces; } }

      > .form-column--item {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 100%;

        > .item-textfields {
          display: flex;
          align-items: center;

          > .mdc-text-field {
            margin-left: 1rem; }

          > .mdc-text-field:not(:last-child) {
            margin-right: 1rem; } }

        > .item-periods {
          display: flex;
          align-items: center;

          > .false-mdc-input-field {
            margin-left: 1rem; }

          > .false-mdc-input-field:not(:last-child) {
            margin-right: 1rem; } }

        > .item-title {
          font-weight: 600;
          margin-bottom: 4px;

          &.item-title--view {
            color: var(--mdc-theme-text-secondary-on-light);
            font-weight: 400;

            > .item-caption {
              display: none; } }

          > .item-title--required {
            color: red; }

          > .item-caption {
            color: var(--mdc-theme-text-secondary-on-light);
            white-space: break-spaces; } }

        > .item-text {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600; }

        > .item-text-headline {
          font-size: 22px;
          line-height: 26px;
          font-weight: 500;
          width: 100%;

          .link {
            color: var(--mdc-theme-text-primary, #354C62);
            text-decoration: underline; }

          a {
            color: var(--mdc-theme-text-primary, #354C62);
            text-decoration: underline;
            font-style: italic; } }

        > .item-text.link {
          text-decoration: underline; }


        > .error-text {
          color: var(--mdc-theme-error);
          font-size: small; } } } } }

.textarea {
  resize: vertical !important; }
// Жду Алинин Акцепт
// max-height: 400px !important
// overflow: hidden
.eye {
  position: absolute;
  right: 0; }
