.message-info-card {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    padding: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    &-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%; }

    &-message {
        display: flex;
        align-items: center; }

    &-icon {
        margin-right: 13px;
        font-size: 24px; }

    &-text {
        font-weight: 500;
        font-size: 16px;
        margin-top: 2px; } }
