.superadmin-users-page {
    .link-to-another-page {
        font-size: 22px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        color: var(--mdc-theme-primary); }
    .link-to-another-page:not(:last-of-type) {
        margin-right: 20px; }
    .search {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        width: 50%; }

    .search-icon {
        position: absolute;
        right: 1rem; }

    .user-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
        grid-gap: 10px;
        list-style: none;
        padding: 0;
        min-height: 245px;

        .user-list-item {
            display: flex;
            flex-direction: column;
            padding: 20px;
            background: white;
            min-height: 212px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

            .user-statusbar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .user-statusbar-title {
                    background-color: #008E1F14;
                    padding: 4px;
                    border-radius: 8px;
                    cursor: pointer; }

                .user-statusbar-btn {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.3px;
                    text-decoration-line: underline;
                    color: #12324F;
                    cursor: pointer; } }

            .user-name-and-position {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-bottom: auto;
                .user-info {
                    display: flex;
                    margin-right: 20px;
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                    max-width: 181px;

                    .user-fullname {
                        margin-bottom: 15px;
                        .user-lastname {
                            font-size: 20px;
                            font-weight: 700;
                            overflow: hidden;
                            text-overflow: ellipsis; }

                        .user-name-and-middlename {
                            overflow: hidden;
                            text-overflow: ellipsis; } }

                    .info-block-wrapper:not(:last-of-type) {
                        margin-bottom: 15px; } }
                .roles {
                    font-size: 18px;
                    font-weight: 500; } }
            .btn-group {
                display: flex;
                width: 100%;
                justify-content: space-between;
                gap: 20px;
                // margin-top: auto
                .btn {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // background: red
                    padding: 2px 0 4px 0;
                    // height: 30px !important
                    // padding: 0px 20px
                    font-weight: 500;
                    font-size: 16px !important;
                    // line-height: 19px
                    letter-spacing: 0.3px;
                    text-transform: capitalize !important;
                    box-shadow: none !important;
                    border-width: 2px;
                    text-decoration: none;
                    color: #12324F;
                    &.erase {
                        border: 2px solid #12324F; }
                    &.edit {
                        background: #12324F;
                        color: white; } } }
            .contacts {
                display: flex;
                flex-direction: column;
                margin-bottom: 17px;
                .phone {
                    color: var(--mdc-theme-text-primary, #354C62) !important;
                    text-decoration: none;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                .email {
                    color: var(--mdc-theme-text-primary, #354C62) !important;
                    overflow: hidden;
                    text-overflow: ellipsis; } } } } }

