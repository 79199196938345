.documents-page {

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }

  .modal-title {
    background-color: var(--mdc-theme-secondary);
    display: flex;
    padding: 9px 24px 9px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: unset;

    i {
      cursor: pointer; } }

  .modal-content {
    color: unset;
    padding: 0;

    hr {
      color: #d8dee1;
      border: 1px solid; }

    > .footer-btn {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      > button {
        width: 50%; } }

    > .doc-container {

      display: flex;
      min-height: 180px;
      flex-wrap: wrap;
      width: 750px; } }

  .docs {
    display: flex;
    color: unset;
    flex-wrap: wrap;

    .doc-file {
      min-height: 180px;
      width: 150px;
      display: flex;
      flex-direction: column;
      padding: .5rem;
      align-items: center;
      height: min-content;

      &.download {
        cursor: pointer; }

      > .pic {
        background-color: var(--mdc-theme-background);
        margin: .5rem;
        border: 2px solid #d8dee1;
        flex: 1 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        height: 110px;
        width: 110px;
        max-height: 110px;
        position: relative;

        > .close {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer; } }

      > span {
        text-align: center;
        display: block;
        font-weight: 400;
        word-break: break-word; } } }

  .dropzone {
    border: 3px dashed #9ca7aa;
    width: 750px;
    height: 220px;
    background-color: var(--mdc-theme-background);
    margin: 15px 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    position: relative;

    > i {
      font-size: 9rem; }

    > div {
      font-size: 1.2rem;
      font-weight: 600; }

    > input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;

      &:hover {
        cursor: pointer; } } } }

.dropzone:hover {
  opacity: 1;
  cursor: pointer; }

.dropzone.dragover {
  opacity: 1; }

.tooltip {
  max-width: 220px;
  word-break: break-word; }

.doc-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: 45px;
  padding: 0px 12px;
  text-align: left;
  // overflow: hidden

  .doc-name {
    display: flex;
    max-width: 80%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word; }

  .doc-extension {
    align-self: flex-end; } }
