.objects {
  min-width: 1035px;
  .module-blocked:after {
    margin-top: 435px; }

  .contract-link {
    width: 70px !important;
    height: 70px !important;

    .contract-icon {
      font-size: 35px;
      width: 70px !important;
      height: 70px !important;
      color: rgba(18, 50, 79, 1); } }

  .with-brigades {
    margin-top: 0; }

  .object-table {
    margin-top: 0;
    padding-bottom: 16px;

    .big-font {
      font-size: 22px; } }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }

  > .object-card:not(:first-child) {
    margin-top: 10px; }

  .card-text {
    padding: 1rem; }

  .card-text > .card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }

  .card-text > .text-row {
    margin-top: 1rem; }

  .card-text .title {
    color: var(--mdc-theme-text-secondary-on-light);
    margin-bottom: 4px; }

  .card-text .under-text {
    font-weight: 500; }

  .brigades .spoiler:nth-child(2n+1) {
    background-color: var(--mdc-theme-background); }

  .brigades .spoiler {
    box-shadow: none;
    margin: 0; }

  .separator {
    margin: 0;
    border-bottom: 0;
    border-top: 1px solid #e1e1e7; }

  .btn-without-underline {
    text-decoration: none;
    cursor: default; }

  .btn-sized {
    text-decoration: none;
    cursor: default;
    width: 8rem;
    display: inline-block;
    text-align: center; }

  .worker-line-block {
    box-shadow: none; }

  .search-input {
    width: 50%;
    margin-bottom: 1.5rem; }

  .objects-text {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.3px;
    text-align: center; } }

@media (max-width: 1246px) , (max-height: 720px) {
  .objects {
    .objects-text {
      font-size: 11px; }
    .period {
      .text {
        font-size: 18px; } } } }
