.about-me-form {
  display: flex;
  flex-direction: column;

  > * {
    box-shadow: none; } }
.about-buttons-save {
  display: flex;
  justify-content: flex-end;
  .about-button-save {
    margin-right: 42px; } }

.style-worker-form {
  justify-content: space-between; }
.about-textarea {
  height: 160px; }
