.dnd-table-body {
  .table-cell {
    .table-cell-inner {
      .dnd-first-cell-link {
        text-overflow: ellipsis;
        overflow: hidden; } } }
  .dnd-first-cell {
    display: block;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 5px 10px 5px 10px;
    text-align: center;
    text-overflow: ellipsis !important;

    .dnd-table-cell-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      min-width: 100%;
      height: 100%;
      -webkit-justify-content: space-between;
      -webkit-align-items: center;
      text-overflow: ellipsis !important;
      .profile-link {
        width: 170px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px; }
      .profile-link {}
      .name {
        text-align: start; }
      a {
        color: #354c62; } }
    &.devider:not(:last-of-type) {
      border-right: 0.5px solid #DEE7EF;
      overflow: visible; } }

  .more_horiz {
    margin-left: 10px;

    &:hover {
      cursor: pointer; } }

  .dialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    //height: 135px
    height: 177px;
    width: 220px;
    top: -5px;
    left: 238px;
    background-color: #FFFFFF;
    padding: 10px;

    .dialog_item {
      width: 100%;
      background: rgba(222, 231, 239, 0.4);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 5px;
      color: #354c62;
      > a {
        text-decoration: none; }
      .item {
        display: flex;
        align-items: center;
        i {
          margin-right: 3px; }
        &:hover {
          color: #0044B4;
          cursor: pointer; }

        &:active {
          color: #003fa8; } } } } }


