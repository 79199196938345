.professions-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .select_category {
    position: relative;
    overflow: visible !important;
    .dialog {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      height: 122px;
      width: 220px;
      top: 0px;
      left: 200px;
      background-color: #FFFFFF;
      padding: 20px;
      z-index: 99;

      .dialog_item {
        cursor: pointer;
        display: flex;
        font-weight: 500;
        width: 100%;
        font-size: 16px;
        padding: 10px 0;
        .item {
          margin-right: auto; } } } }


  .more_horiz {
    cursor: pointer;
    margin-left: 10px; }
  .professions-dialog-description {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #12324F;
    flex-direction: column;
    .professions-dialog-item {
      cursor: pointer;
      display: flex;
      font-weight: 500;
      width: 100%;
      font-size: 16px;
      padding: 20px;
      border-bottom: 1px solid #E1E1E7;
      .item-check-radio {
        margin-right: 18px; }
      .item-title {} } }


  .custom-dialog-content {
    padding: 20px 24px 20px 24px; } }

