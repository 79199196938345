.worker-time-keeping-page {
    & &-title {
        margin-right: 24px; }

    & &-title-select {
        max-width: 200px;
        width: 100%; }

    & &-title-date {
        font-weight: 500;
        text-decoration: underline;
        font-size: 22px;
        cursor: pointer; }

    & &-week-scroller {
        margin-bottom: 15px; }

    & &-foreman {
        color: inherit;
        text-decoration: underline; }

    & &-accordion-shift {

        &-body {
            margin: 0 24px; }

        &-item {
            padding: 20px 0; }

        &-worker {
            display: flex;
            justify-content: space-between; }

        &-time-wrapper {
            display: flex; }

        &-time-body {
            margin: 0 10px; }

        &-time {
            display: flex;
            align-items: center;
            position: relative;
            right: 11px; }

        &-field {
            max-width: 100%;

            &-label {
                font-weight: 400;
                font-size: 20px;
                color: #697077;
                margin-bottom: 10px;

                &-extended {
                    margin-bottom: 20px; } }

            &-value {
                font-weight: 700;
                font-size: 22px;
                color: #12324F; } } } }
