.week-stats-info {
    margin-bottom: 15px;

    &-item {
        font-weight: 500;
        font-size: 22px;
        border-top: 8px solid #f5f5f8;
        padding: 15px 24px;
        color: #12324F;
        display: flex;
        align-items: center;
        justify-content: space-between; } }
