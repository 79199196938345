#navbar.nav-closed {
  max-width: 62px; }

#navbar {
  transition: max-width 0.3s ease-in-out;
  background-color: var(--mdc-theme-primary);
  color: white;
  max-width: 255px;

  .navbar-notice {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: red;
    top: 4px;
    left: 4px; }

  .navbar-bottom {
    margin-top: auto !important;

    .support {
      // position: absolute !important
      bottom: 80px !important;
      padding: 10px;
      min-height: 20px !important;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 30px;
      color: white;
      font-size: 14px !important; }

    .navbar-bottom-item {
      align-items: center;
      color: white;
      overflow: hidden;
      display: flex;
      transition: padding, margin 0.3s ease-in-out;
      font-size: 12px;
      min-height: 35px;
      cursor: pointer;
      padding: 11px 8px 8px 14px;
      margin: 2px 8px;

      i {
        margin-right: 8px; }

      a {
        color: white; }

      .navbar-bottom-item-notification {
        background-color: red;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: 8px; } }

    .navbar-bottom-item-active {
      background-color: whitesmoke;
      color: var(--mdc-theme-primary); }

    .navbar-bottom-item-mini {
      position: relative;
      width: 45px;
      padding: 3px 0 0 0;
      display: flex;
      transition: padding, margin 0.3s ease-in-out;
      justify-content: center;
      min-height: 35px;
      margin: 2px 8px;

      i {
        margin-right: 0; }

      * {
        font-size: 28px; }

      .navbar-bottom-item-notification {
        margin-left: 0;
        position: absolute;
        top: 4px;
        right: 6px; } }

    .version {
      margin-top: 10px;
      overflow: hidden;
      transition: padding 0.3s ease-in-out;
      padding-left: 20px;
      font-size: 12px;
      min-height: 50px; }

    .version-mini {
      margin-top: 10px;
      overflow: hidden;
      transition: padding 0.3s ease-in-out;
      font-size: 12px;
      bottom: 20px;
      text-align: center;
      min-height: 50px; }

    .navbar-bottom-active {
      background-color: #Ffffff;
      color: #12324F;

      a {
        color: #12324F; } }

    .navbar-bottom-survey-notice {
      position: fixed;
      bottom: 45px;
      left: 290px;
      min-height: 20px;
      z-index: 2;

      .navbar-bottom-survey-notice-wrapper {
        position: relative;
        padding: 16px 10px 16px 30px;
        width: 400px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

        .navbar-bottom-survey-notice-body {
          display: flex;
          justify-content: space-between;
          color: #212121;
          padding-bottom: 20px; }

        .navbar-bottom-survey-notice-body-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.3px; }
        i {
          cursor: pointer;
          color: #9AA2B0; }

        .navbar-bottom-survey-notice-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 17px;

          button {
            width: 165px; } } }

      .navbar-bottom-survey-notice-wrapper::after, .navbar-bottom-survey-notice-wrapper::before {
        content: '';
        position: absolute;
        background: #FFFFFF;
        left: -15px;
        bottom: 20px;
        width: 30px;
        height: 30px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        z-index: -1;
        /* Прячем за основным блоком */
        transform: rotate(45deg);
        /* Поворачиваем на 45º */
        -webkit-transform: rotate(45deg); }

      .navbar-bottom-survey-notice-wrapper::before {
        z-index: 1;
        box-shadow: none; } } }

  .show-hide-menu {
    display: flex;
    margin-left: auto;
    font-size: 32px;
    margin: 20px 16px 0px;
    margin-left: auto;
    cursor: pointer; }


  .show-hide-menu-closed {
    display: flex;
    margin: 20px 16px 0px;
    // margin-left: auto
    font-size: 32px;
    cursor: pointer; }

  > .mdc-drawer__content {

    > .mdc-list {

      > .list-item {
        position: relative;
        margin: 2px 8px 8px;

        .list-item-off {
          opacity: 0.4; }

        > a {
          all: unset;

          > .mdc-list-item:not(.mdc-list-item--activated) {
            color: white;

            > .icon {
              color: white;

              &.icon--number {
                > .number {
                  color: var(--mdc-theme-primary); } } } }

          > .mdc-list-item--activated {
            color: var(--mdc-theme-primary);
            background-color: white;

            > .icon {
              color: var(--mdc-theme-primary);

              &.icon--number {
                > .number {
                  color: white; } } } }

          > li {
            margin: 0;

            > .icon {
              margin-right: 8px;

              > i {
                font-size: 28px; }

              &.icon--number {
                display: flex;
                align-items: center;
                justify-content: center;

                > .number {
                  position: absolute;
                  font-size: 12px; } } } } } } } } }


