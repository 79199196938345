.tabs {
  .tab-btn-title {
    flex-grow: 1; }
  > .tab-btns {
    display: flex;
    height: 60px;

    > .tab-btn {
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #E1E1E7;
      position: relative;
      text-align: center;

      .tab-edit_note {
        flex-basis: 10%; } }

    > .tab-btn.active {
      background-color: #fff;
      cursor: default; } } }

@media screen and (max-width: 1350px) {
  .tabs {

    > .tab-btns {
      display: flex;
      height: 60px;

      > .tab-btn {
        font-size: 13px; } } } }
