
.form-field-label-value {
  display: flex;
  margin-bottom: 14px;
  align-items: center; }

.form-field-label-header {
  margin: 0 10px 0 0;
  display: flex;
  flex-direction: row;

  .form-field-label-title {
    margin: 0; }

  .form-field-label-caption {
    margin: 0 0 0 10px;
    color: var(--mdc-theme-text-secondary-on-light); } }
