.table-cell {
    display: block;
    align-items: center;
    justify-content: center;
    height: 100%;
    // flex: 0 1 20px
    width: 100%;
    // min-width: auto
    padding: 15px;
    text-overflow: ellipsis !important;
    // white-space: nowrap !important
    overflow: hidden !important;
    // .table-cell-inner
    //     display: flex
    //     flex-direction: column
    .table-cell-inner {
        display: flex;
        // padding: 10px 0px
        min-width: 100%;
        height: 100%;
        -webkit-justify-content: center;
        -webkit-align-items: center;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis !important;
        overflow: hidden !important; }
    &.devider:not(:last-of-type) {
        border-right: 0.5px solid #DEE7EF; } }

