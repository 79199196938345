.page-default {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  min-height: 210px;
  background: #FFFFFF;
  .page-default-text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1; } }
