.need-page {
    .align-right {
        > input {
            text-align: right; } }

    .mdc-dialog__surface {
        > .dialog-title {
            height: 68px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--mdc-theme-secondary);

            > .mdc-icon-button {
                position: absolute;
                right: 10px;
                top: 10px; } }

        > .dialog-body {
            display: flex;
            flex-direction: column;
            max-height: 50vh;
            min-width: 600px;
            overflow: auto;
            padding: 1rem;

            > .mdc-list-divider {
                margin-block: .6rem; }

            > .dialog-item {
                display: flex;

                > div {
                    width: 33.3%;

                    &:not(:last-child) {
                        margin-left: 1rem; } } } } } }
