.public-organization-profile-template {
    .public-organization-profile-template-inner {
        .form-wrapper {
            .form {
                display: flex;
                flex-direction: column;
                .form-columns-wrapper {
                    display: flex;
                    .form-column {
                        width: 50%;
                        .option-row {
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;
                            .required-by-user,
                            .required-default {
                                .mdc-checkbox__checkmark {
                                    display: none; }
                                .mdc-checkbox__background::after {
                                    position: absolute;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    content: "*";
                                    color: white;
                                    top: 9.5px;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    font-size: 32px;
                                    font-weight: 700; } }
                            .required-by-user.unchecked {
                                .mdc-checkbox__background {
                                    background: white !important; }
                                .mdc-checkbox__background::after {
                                    display: none; } }
                            .required-default.mdc-checkbox--disabled,
                            .required-by-user.mdc-checkbox--disabled,
                            .hidden.mdc-checkbox--disabled {
                                .mdc-checkbox__background {
                                    border-color: #9AA2B0 !important;
                                    background: #9AA2B0 !important; } }
                            .required-by-user.mdc-checkbox--disabled {
                                .mdc-checkbox__background {
                                    background: white !important; }
                                .mdc-checkbox__background::after {
                                    display: none;




                                    //             .required.mdc-checkbox__background::after
                                    // position: absolute
                                    // display: flex
                                    // align-items: center
                                    // justify-content: center
                                    // content: "*"
                                    // color: white
                                    // top: 7px
                                    // right: 0
                                    // bottom: 0
                                    // left: 0
                                    // font-size: 30px
                                    // .active
 } } } } } } } } }                                    //     color: var(--mdc-theme-primary)

