.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .form-field-header {
    display: flex;
    align-items: center;
    margin: 8px 0 16px; }

  .form-field-title {
    margin: 0; }

  .form-field-caption {
    margin-left: 8px;
    color: #9aa2b0; } }


.button {
  min-height: 27px;
  padding-top: 1px;
  white-space: nowrap;
  display: flex;
  flex: 1;
  font-size: 13px; }
