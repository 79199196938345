.create-user-page {
  .create-user-page-roles-settings {
    margin-top: 15px;
    .lock {
      padding: 8px; }
    .create-user-page-roles-header {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #5C6E8C;
      margin-bottom: 15px; }

    .create-user-page-roles {
      display: flex;
      flex-wrap: wrap;

      .create-user-page-role {
        display: flex;
        align-items: center;
        flex: 0 1 50%;
        margin-bottom: 15px; }

      .create-user-page-role-off {
        opacity: 0.4;

        a {
          padding: 0 5px; } } }

    .create-user-page-roles-error {
      color: #b00020;
      font-size: small; } }

  .go-to-users {
    font-size: 22px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    color: var(--mdc-theme-primary); }

  .form-component {
    overflow: visible; } }
