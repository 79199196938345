.workers {
  min-width: 935px;

  .contract-link {
    width: 70px !important;
    height: 70px !important;
    .contract-icon {
      font-size: 35px;
      width: 70px !important;
      height: 70px !important;
      color: rgba(18, 50, 79, 1); } }

  .page-content {
    margin-top: 10px; }

  .table {
    &.with-brigades {
      margin-top: 0px; }

    .no-data-notice {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding-top: 300px;
      margin-top: -300px;
      justify-content: center;
      align-items: center;
      color: var(--mdc-theme-text-primary, #354C62);
      font-size: 24px;
      font-weight: 700;
      pointer-events: none; } }

  .tabs {
    background-color: var(--mdc-theme-background);
    box-shadow: none; }

  .workers-table:not(.headings) {
    margin-top: 125px; }

  .workers-table {
    @media screen and (max-width: 1350px) {
      grid-template-columns: 10% 20% 20% 15% 20% auto !important; }

    .big-font {
      font-size: 22px; } }
  .workers-table.headings {
    position: absolute;
    grid-column-gap: 2px;
    width: 100%;
    top: 173px;
    z-index: 2;
    transition: margin-top 0.3s ease-in-out;
    min-width: 935px;
    pointer-events: none;
    background-color: var(--mdc-theme-background); }
  .workers-table.headings.scrolled-to-top {
    position: fixed;
    left: 271px;
    right: 28px;
    top: 54px;
    width: auto; }
  .workers-table.in-brigade {
    margin-top: 0;
    grid-row-gap: 2px; }

  .worker-line-block {
    padding-right: 0; }

  .span-buttons {
    width: 60%;
    grid-gap: 0;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      padding: 0 10px;
      text-align: center; }

    .btn_without-underline {
      text-decoration: none;
      cursor: default; }

    .btn_worker-card {
      width: 30%; }

    .btn_date {
      width: 20%; }

    .btn_need-number {
      width: 15%; }

    .btn_subdivision {
      width: 35%;
      text-overflow: ellipsis; } }

  > .spoiler > .body > .worker-line-block {
    box-shadow: none; }

  > .worker-line-block:not(:first-child) {
    margin-top: 10px; }

  .search {
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;

    > label {
      display: flex;
      padding-left: 6px; } }

  .separator {
    margin: 0;
    border-bottom: 0;
    border-top: 1px solid #e1e1e7; }

  .no-data-notice {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 500px;
    margin-top: -300px;
    justify-content: center;
    align-items: center;
    color: var(--mdc-theme-text-primary, #354C62);
    font-size: 24px;
    font-weight: 700; } }
