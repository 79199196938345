.reviews {
  display: flex;
  flex-direction: column;

  .worker-profile-review {
    display: flex;
    border-top: 1px solid #E1E1E7;
    padding: 1.3rem;

    &:first-child {
      border: none; } }

  .worker-profile-block {
    margin-right: 0.8rem;
    min-width: 147px; }

  .raiting {
    margin: 0.3rem 0 1rem; }

  .employment-time {
    white-space: pre-line; }

  .worker-profile-content {
    width: 100%; }

  .author-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem; }

  .role {
    margin-top: 0.3rem;
    font-weight: 600; }

  .name {
    color: var(--mdc-theme-text-secondary-on-light); }

  .comment {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    font-weight: 600; } }
