.organization-profile-moderator {
  .tab-btn-title {
    font-size: 18px; }

  .connected-modules {
    background-color: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 8;
    top: 55px;
    right: 0;

    .custom-dialog-module-activate {
      padding: 20px 50px;
      width: 500px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #12324F;
      flex-direction: column; }

    .custom-dialog-content {
      max-height: none;

      .doc-container {
        max-height: none; } }

    .connected-modules-empty {
      display: flex;
      height: 200px;
      align-items: center;
      justify-content: center; }

    .connected-modules-dialog {
      padding-bottom: 20px;
      //min-height: 200px

      .connected-modules-divider {
        border-bottom: 0.5px solid #5C6E8C; }

      .connected-modules-field {
        padding: 20px 3px 0px;

        .connected-modules-file {
          margin-top: 33px;

          .connected-modules-file-loaded {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 155px;
            height: 155px;
            background-color: #FFFFFF;
            box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.1);
            border: 1px solid var(--mdc-theme-text-secondary-on-light);

            div {
              margin: auto;
              min-height: 115px;
              min-width: 115px;
              max-width: 115px;
              height: 100%;
              width: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain; }

            .zoom_in {
              position: absolute;
              top: 0;
              opacity: 0;
              font-size: 50px;
              justify-content: center;
              align-items: center;
              color: white;
              transition: background, opacity 0.1s ease-in-out;
              cursor: pointer;
              padding: 52px;

              &:hover {
                opacity: 1;
                background: rgba(0, 0, 0, 0.35); } }

            .close {
              z-index: 9;
              cursor: pointer;
              position: absolute;
              top: 5px;
              right: 5px; } }

          .connected-modules-file-upload {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 155px;
            height: 155px;
            background: #FFFFFF;
            border: 1px solid #9AA2B0;
            box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.1);

            div {
              margin: auto;
              height: 115px;
              width: 115px;
              background: #F5F5F8;
              border: 1px solid #9AA2B0;
              box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.1); }

            i {
              position: absolute;
              top: 0;
              padding: 65px; }

            input {
              position: absolute;
              top: 0;
              z-index: 10;
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer; } } }

        .connected-modules-headline {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          color: #9D9D9D;
          padding-bottom: 5px; } } }


    .connected-modules-select {
      .connected-modules-select-field {
        width: 600px; } }

    .connected-modules-wrapper {
      padding: 5px 0;

      .connected-modules-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 59px;
        padding: 10px 20px;
        word-break: break-word;

        .connected-modules-cell-btn {
          background-color: #12324F;
          cursor: pointer;
          color: #FFFFFF;
          margin: 0 5px;
          width: 24px;
          height: 24px;
          border-radius: 2px; } }

      .connected-modules-row {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.3px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px; }

      .connected-modules-header {
        color: #5C6E8C; }

      .connected-modules-body {
        color: #12324F;

        .connected-modules-row {
          border-top: 0.5px solid #DEE7EF; }

        .connected-modules-module {
          padding: 30px 20px 35px; } }

      .connected-modules-module {
        flex-basis: 25%;
        justify-content: left; }

      .connected-modules-status {
        flex-basis: 18%;

        div {
          padding: 10px;
          border-radius: 10px; } }

      .connected-modules-endDate {
        flex-basis: 15%; }

      .connected-modules-reason {
        flex-basis: 22%; }

      .connected-modules-action {
        flex-basis: 10%; }

      .connected-modules-subscription {
        flex-basis: 10%;

        .connected-modules-subscription-btn {
          cursor: pointer;
          text-decoration: underline; } } } } }

