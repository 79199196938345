.worker-profile-form {
  display: flex;
  border-radius: 2px;
  margin-bottom: 16px;

  .form-header {
    display: flex;
    justify-content: space-between;

    .fixed-buttons-container {
      padding-top: 20px !important; } } }

.worker-profile-buttons-save {
  display: flex;
  align-items: flex-start;
  .button-save {
    margin-right: 42px;
    margin-left: 16px; } }
