.anthropometry-form {
  display: flex;
  position: relative;
  box-shadow: none;
  > * {
    margin-top: 20px; } }


.anthropometry-form-buttons-save {
  display: flex;
  position: absolute;
  right: 0;
  top: -20;

  .button-save {
    margin-right: 42px; } }

.anthropometry-input {
  padding: 10px;
  width: 100%; }
