.worker-experience-page {

    .experience-list {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 50% 50%;
        list-style: none;
        padding: 0;

        .experience-item {
            padding: 1rem;
            background: white;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

            .info-block-wrapper:not(:last-of-type) {
                margin-bottom: 1rem; }

            .link-to-org-profile {
                color: var(--mdc-theme-text-primary, #354C62); }
            .experience-item-contract-card {
                display: flex;
                align-items: center;
                cursor: pointer;
                max-width: 100px;
                i {
                    margin-right: 8px; } } } } }

@media screen and (max-width:1200px) {
    .experience-list {
        grid-template-columns: 100% !important; } }




