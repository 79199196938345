.public-worker-profile-page-dump {
  position: relative;
  .edit_note {
    margin-left: 10px;
    margin-top: 5px; }
  .contactEmailConfirmed {
    display: flex;
    margin-top: 5px;

    .check_circle {
      color: #00E23F;
      margin-right: 5px; }
    .info {
      color: #ED0000;
      margin-right: 5px; }

    .confirmed {
      font-weight: 500; } }

  .info-item {
    display: flex;
    align-items: center;

    .info-item-version {
      opacity: 0.5;
      display: flex;
      align-items: center; }

    .info-block-content {
      overflow: visible;
      position: relative;

      .version-buttons {
        position: absolute;
        right: 0;
        bottom: -23px;

        i {
          cursor: pointer;
          margin: 0 4px; } } }

    .keyboard_double_arrow_right {
      margin: 0 10px; } }

  .section {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #5C6E8C; }

  .section:last-of-type {
    border: none !important; }

  .section-title {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #12324F; }

  .telephone,
  .email {
    color: #12324F; }

  .documents-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    .documents-list-versions {
      display: flex;

      .document-wrapper {
        width: 212px; } } }

  .worker-panel {
    display: flex;
    background-color: white;
    margin-bottom: 10px;

    .inner-container {
      display: flex;
      width: 100%;
      padding: 24px;

      .worker-info-wrapper {
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        width: 100%;

        .worker-avatar {
          display: flex;
          width: 411px;
          height: 232px;
          background: white;
          border: 2px solid #9AA2B0;
          // background-image: url('../../misc/user-Avatar-default.svg')
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 172px;
          margin-right: 26px;

          .avatar-preview {
            width: 100%;
            height: 100%;
            object-fit: cover; } }

        .worker-info-list {
          display: flex;
          list-style: none;
          flex-direction: column;
          padding: 0;
          margin: 0;
          margin-top: -4px;

          .info-item:not(:last-of-type) {
            margin-bottom: 1rem; }

          .info-item {

            .telephone {
              text-decoration: none;
              cursor: pointer; }

            .email {
              text-decoration: underline;
              cursor: pointer; }

            .rating {
              display: flex;
              align-items: center; }

            .rate,
            .loyalty {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.3px;
              color: #12324F; }

            .loyalty {
              padding-left: 20px;
              background-image: url('../../../misc/loyalty-icon.svg');
              background-repeat: no-repeat;
              background-position: left center; } } }

        .btn-panel-list {
          display: flex;
          flex-direction: column;
          margin: 0;
          margin-left: auto;
          margin-top: 4px;
          list-style: none;

          .btn-panel-item:not(:last-of-type) {
            margin-bottom: 16px; }

          .btn-panel-item {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
              min-width: 256px;
              padding: 6px 12px;
              border-radius: 0;
              background-color: #12324F;
              border: none;
              color: #F5F5F8;
              font-weight: 500;
              font-size: 13px;
              line-height: 15px;
              text-align: center;
              letter-spacing: 0.6px;
              cursor: pointer; } } } } } } }

@media screen and (max-width: 1300px) {
  .worker-info-wrapper {
    flex-direction: column;
    align-items: center;

    .worker-avatar {}

    .worker-info-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      flex-direction: row !important;
      margin-bottom: 24px !important;
      justify-content: center;

      .info-item:not(:last-of-type) {
        margin-bottom: 0px !important; }

      .info-item {
        display: flex;
        flex-direction: column; } }

    .btn-panel-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-left: 0 !important;
      padding-left: 0 !important;

      .btn-panel-item:not(:last-of-type) {
        margin-bottom: 0px !important; } } } }
