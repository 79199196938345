.workers-new-page {
  .dialog-filter,
  .dialog-filter-last {
    position: absolute;
    right: -27px;
    z-index: 3;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    background-color: #ffffff;
    align-items: start;
    padding: 15px 15px 15px 15px;
    justify-content: center;
    text-align: start;
    font-weight: 400;
    color: #354c62;
    width: 300px;
    cursor: default;

    .dialog-filter-input {
      display: flex;
      width: 100%;

      .dialog-select {
        flex-basis: 100%; }

      .icon {
        cursor: pointer;
        align-self: center;
        margin-left: auto; }

      .dialog-date {
        display: flex;
        height: 100px;

        .filter_alt {
          margin-left: 50px; }

        .dialog-date-fields {
          display: flex;
          flex-direction: column;
          justify-content: space-between; } } }

    .dialog-filter-list {
      .items {
        margin-top: 10px;
        height: 300px;
        overflow: auto;
        border: 1px solid #DAE6F6;
        border-radius: 5px;

        .item {
          display: flex;
          width: 100%;
          align-items: center;
          border-top: 1px solid #DAE6F6;
          padding: 5px;
          max-height: 53px;

          .off-checkbox {
            .mdc-checkbox__background {
              background-color: #Ffffff !important; } }

          &:first-child {
            border: none; }

          .text {
            text-overflow: ellipsis;
            width: 90%; } } }

      .dialog-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .dialog-btn {
          display: flex;
          align-items: center;
          background-color: #12324F;
          border-radius: 5px;
          width: 132px;
          padding: 5px;
          color: #FFFFFF;
          transition: box-shadow 0.15s ease-in-out;

          div {
            padding-bottom: 2px; }

          &:last-child {
            background-color: #FFFFFF;
            border: 1px solid #12324F;
            width: 122px;
            color: #12324F; }

          &:hover {
            cursor: pointer;
            opacity: 0.9;
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2); }

          &:active {
            opacity: 0.85; } } } }


    .dialog-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      border-top: 2px solid rgba(0, 68, 180, 0.5);
      margin-top: 10px;
      padding: 10px 50px 5px 50px;

      div {
        padding-top: 1px;
        white-space: nowrap; }

      .icon {
        margin-right: 10px; }

      &:hover {
        color: #0044B4; }

      &:active {
        color: #003fa8; } }

    .dialog-other-buttons {
      display: flex;
      justify-content: space-between; }

    .dialog-items {
      height: 360px;
      padding: 0px 15px 0px 15px;
      overflow: auto; }

    .dialog-switch {
      border-top: 1px solid #DAE6F6;
      padding: 6px 0;

      &:last-child {
        padding-bottom: 0; } }

    .dialog-close {
      cursor: pointer;
      width: 60px;
      border-bottom: 1px solid #002f7e;
      margin: 5px auto 10px auto; } } }
