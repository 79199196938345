.module-block-notification {
  padding: 20px;
  margin-top: 11px;
  margin-bottom: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  width: 819px;

  .module-block-notification-role {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #12324F; }
  .module-block-notification-body {
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #12324F;
    padding: 25px 0;

    ul {
      padding: 0;
      margin: 0;
      padding-left: 16px;

      li {
        margin-bottom: 15px; } } }

  .module-block-notification-btn {}
  .module-block-notification-dialog {
    margin: 40px 0;
    color: #12324F;
    font-size: 16px;
    text-align: center; } }

