.time-keeping-page {
    min-width: 1110px;

    .module-blocked:after {
        margin-top: 510px; }

    .link-to-public-profile {
        color: #12324F; }

    > .page-title-component {
        margin-bottom: 10px;

        > .title {
            > div:last-child:not(:first-child) {
                min-width: 140px; } } }

    .shift-select-filter {
        margin-left: 10px;
        width: 130px; }

    .close-open-shift-wrapper {
        display: flex;
        width: 100%;
        position: relative;
        margin: 1rem auto 2rem;
        align-items: flex-end;
        justify-content: center;
        flex-wrap: wrap;

        @media screen and (max-width: 1300px) {
            display: grid;
            grid-template-columns: repeat(2, minmax(135px, 25%));
            grid-gap: 1rem; }

        .plus-icon {
            position: absolute;
            top: 35px;
            left: 10px;
            pointer-events: none; }

        .fixed-buttons-container {
            padding: 0; }

        .fixed-buttons-container:first-of-type {

            @media screen and (max-width: 1300px) {
                grid-column: 1/1;
                grid-row: 2/2; }

            .mdc-button__label {
                display: inline-block; } }

        .form-component {
            position: relative;
            margin: 0 2rem;
            min-width: 200px;

            @media screen and (max-width: 1300px) {
                grid-column: 1/3;
                grid-row: 1/1;
                margin: 0; }

            .error-text {
                position: absolute;
                bottom: -15px; } }

        .form-block {
            padding: 0 !important;
            background: transparent;
            box-shadow: none; } }

    > .week-scroller {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-top: 10px;

        > .page-button {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 84px;
            width: 2.8rem;
            margin-bottom: 4px;

            cursor: pointer; }

        > .week-block {
            display: flex;
            flex-direction: column;
            padding-bottom: 4px;

            &:first-child {
                padding-right: 4px; }

            &:not(:first-child):not(:last-child) {
                padding-inline: 4px; }

            &:last-child {
                padding-left: 4px; }

            &.week-block--current {
                background: var(--mdc-theme-secondary);

                > .week-title {
                    font-size: 20px;
                    font-weight: 700; } }

            > .week-title {
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: pre;
                text-align: center;
                height: 4rem;
                font-weight: 500; }

            > .days-container {
                display: flex;
                column-gap: 4px;

                > .day-block {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    border-radius: 2px;
                    padding-block: 4px;
                    height: 84px;
                    width: 2.8rem;

                    cursor: pointer;

                    &.day-block--current {
                        > .day-number {
                            background: var(--mdc-theme-secondary);
                            border-radius: 50%; } }

                    &.day-block--active {
                        outline: 2px solid var(--mdc-theme-primary); }

                    > .day-number {
                        font-weight: 600;
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center; } } } } } }
