.group-changing {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .profession-group-textfield {
    margin-top: 20px; }
  .professions-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .buttons {
      display: flex;
      width: 400px; }
    .group-changing-visible-selected {
      display: flex;
      align-items: center;
      padding-top: 1rem; } } }
