.report-page {

  > .main-card {

    display: flex;
    padding: 1rem;
    margin-bottom: 0.5rem;

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      > span {
        &:first-child {
          margin-bottom: 3px;
          color: var(--mdc-theme-text-secondary-on-light); }
        &:last-child {
          font-weight: 500; } } } }

  > .table-card {
    display: flex;
    background-color: transparent;

    .deposit > thead {
      border-right: 1px solid #e1e1e7; }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      align-self: flex-start;
      background: white;

      th {
        color: var(--mdc-theme-text-primary, #354C62);
        padding: 1.5rem; }



      td {
        padding: 1rem;
        color: var(--mdc-theme-text-primary, #354C62);
        font-weight: 500;
        border: 1px solid #e1e1e7;

        > span {
          // display: Table
 } } } }          // margin: 0 auto 0 33%

  // .deposit
  //   .value
  //     display: flex
  //     justify-content: flex-end
  //     // text-align: right
  //     border-right: none !important
  //     color: green

  // .charges
  //   .value
  //     display: flex
  //     justify-content: flex-end
  //     color: red
  .value,
  .date {
    text-align: right; }

  .charges {
    .value {
      color: red; } }

  .deposit {
    .value {
      color: green; } } }
