.document-wrapper {
  flex: 0 0 212px;
  margin: 0;
  margin-right: 12px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border: 2px solid #E1E1E7;

  .document-inner {
    position: relative;
    padding: 24px;
    width: 100%;
    .edit_note {
      position: absolute;
      right: 5px;
      top: 5px; }
    .version-buttons {
      position: absolute;
      bottom: -110px;
      right: 5px;

      i {
        cursor: pointer;
        margin: 0 4px; } }

    .text {
      display: inline-block;
      margin: 0;
      font-weight: 500;
      margin-bottom: 1rem; }

    .certificate-number {
      margin-bottom: 12px; }

    .info-wrapper {
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 5px; }

      .issue-date {
        font-weight: 500;
        color: #12324F;
        margin-bottom: 12px; } }

    .img-wrapper {
      position: relative;
      width: 100%;
      height: 160px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid var(--mdc-theme-text-secondary-on-light);
      cursor: pointer; }

    .img-wrapper:hover > .preview-loupe {
      display: flex; }

    .preview-loupe {
      display: none;
      position: absolute;
      font-size: 50px;
      color: white;
      top: 0;
      right: -1px;
      bottom: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.35); } } }




