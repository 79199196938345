.documents-subpage-container {
    background-color: white;
    .version-buttons {
        position: absolute;
        bottom: 0;
        margin-left: 320px;
        i {
            cursor: pointer;
            margin: 0 4px; } }
    .documents-passport-version {
        display: flex;
        justify-content: flex-end;
        .documents-passport-version-item {
            display: flex;
            justify-content: space-between;
            padding: 0 6px 0 6px;
            margin: 7px 0;
            align-items: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #12324F;
            cursor: pointer;
            text-decoration: #002f7e;
            text-decoration-line: underline; } }

    .document-status {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 113px;
        margin-top: 10px;

        .document-status-item {
            display: flex;
            justify-content: space-between;
            padding: 0 6px 0 6px;
            margin: 7px 0;
            align-items: center;

            i {
                margin-right: 10px; }

            div {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.3px;
                color: #12324F; } }

        .document-status-pointer {
            cursor: pointer;
            margin: 12px 0;

            div {
                width: 100%;
                padding: 5px 0;
                text-align: center;
                text-decoration: #002f7e;
                text-decoration-line: underline; } } }

    .documents-status-btn {
        margin-bottom: 20px; }
    .citizenship {
        margin-bottom: 1.5rem; }

    .passport-container {
        display: flex;
        border-bottom: none;

        .info-wrapper {
            margin-bottom: 1.5rem; } }

    .passport-serial-number-wrapper {
        display: flex;

        > .info-wrapper {
            margin-right: 50px; } }

    .living-adress {
        .info-block-wrapper:not(:last-of-type) {
            margin-bottom: 1.5rem; } } }
