.error-template-page {
    > .container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99999999;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        > .description {
            width: 40%;
            text-align: center; } }
    .fixed-buttons-container {
        display: flex; } }
