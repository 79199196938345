.documents-subpage-containers {
  background: white;
  position: relative;
  padding: 32px;

  .buttons-edit {
    position: absolute;
    right: 24px;
    top: 24px; }

  .citizenship {
    margin-bottom: 1.5rem; }

  .passport-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    border-bottom: none;

    .info-wrapper {
      margin-bottom: 1.5rem; }

    .passport-serial-number-wrapper {
      display: flex;

      > .info-wrapper {
        margin-right: 50px; } }

    .living-adress {
      .info-block-wrapper:not(:last-of-type) {
        margin-bottom: 1.5rem; } } }

  .documents-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0; } }
