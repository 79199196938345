.no-padding {
    padding: 0px !important; }
.feedback-page {
    display: flex;
    overflow: hidden;
    .side-bar-wrapper {
        transition: width 0.3s ease-in-out;
        width: 287px;
        padding: 16px;
        background: #5C6E8C;
        color: white;
        position: fixed;
        height: 100%;
        z-index: 3;

        .show-icon {
            margin-left: auto;
            width: 24px;
            cursor: pointer; }
        .show-icon-show {
            padding-left: 18px;
            margin-left: 0; }
        .nav-section {
            overflow: hidden;
            cursor: pointer;
            padding: 0px 0px 0px 0px;
            .nav-item-link {
                display: flex;
                align-items: center;
                text-decoration: none;
                height: 56px;
                padding: 18px 23px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.2px;
                color: #F5F5F8;
                margin: 0 12px;

                &.active {
                    background: white;
                    color: var(--mdc-theme-primary); }
                .nav-item-title {
                    margin-left: 22px;
                    max-height: 32px;
                    overflow: hidden; }
                .nav-item-title-show {
                    margin-left: 0;
                    display: none; }
                .nav-item-notification {
                    background-color: red;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-left: 8px; } }
            .nav-item-link-show {
                position: relative;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 11px 5px 11px 5px;
                .nav-item-notification {
                    margin-left: 0;
                    position: absolute;
                    top: 14px;
                    right: 6px; } } } }

    .side-bar-wrapper-show {
        width: 61px;
        padding: 16px 2px;
        background: #5C6E8C;
        color: white;
        position: fixed;
        height: 100%; }

    .content-wrapper {
        transition: margin-left 0.3s ease-in-out;
        padding: 20px;
        width: 100%;
        margin-left: 287px;
        overflow: auto; }
    .content-wrapper-show {
        padding: 20px;
        width: 100%;
        margin-left: 61px;
        overflow: auto; } }
