.singin-moderator-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    margin: 0 auto;

    .tabs {}

    > .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100% !important;

        > .container-body {
            display: flex;
            flex-direction: column;
            width: 70% !important;
            .container-body-field-input {
                margin-top: 20px; }
            .container-body-field-btn {
                margin-top: 40px; } }
        > .footer-message {
            margin-top: 1rem;
            // height: 2rem
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .span-buttons {
                margin-left: 0 !important;
                justify-content: center !important; }

            .back-to-auth-btn,
            .support {
                margin-top: 20px; }
            .consent_to_processing {
                display: flex;
                span {
                    margin: auto 0; } }
            .support {
                font-weight: bold; } } } }
