.documents-form-wrapper {
  padding: 12px;
  position: relative;
  background: #FFFFFF;

  .documents-form-buttons-save {
    display: flex;
    position: absolute;
    right: -10;
    top: -10;

    .button-save {
      margin-right: 42px; } }

  .documents-form-field-title {
    margin-top: 10px;
    font-size: 1rem; }

  .required-field {
    color: red; } }
