.mobilization-page {
    min-width: 990px;

    .mobilization-table {
        margin-top: 20px; }

    .search-input {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        width: 100%; }

    .search-icon {
        position: absolute;
        right: 1rem; }

    .search-icon:hover::before {
        background: none; }

    .link-to-template {
        font-size: 18px;
        color: var(--mdc-theme-text-primary);
        font-weight: 500; }

    .link-to-template:not(:last-of-type) {
        margin-right: 2rem; }

    .sort-btns-panel {
        display: flex;
        align-items: center;

        .sort-btn {
            position: relative;
            display: flex;
            padding: 8px 3rem;
            background-color: white;
            outline: 2px solid #12324F;
            color: 12324F;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            align-items: center;
            vertical-align: center;
            cursor: pointer;

            .sort-btn-text {
                margin-top: -3px;
                text-align: center; }

            .close-sort-btn {
                position: absolute;
                right: 10px;
                width: 30px;
                height: 30px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center; } }

        .sort-btn:not(last-of-type) {
            margin-right: 1rem; } }
    .mobilize-status {
        .table-cell-inner {
            flex-direction: column !important;
            align-items: flex-start;
            .mobilize-status-wrapper {
                display: flex;
                align-items: center;
                align-items: center;

                .mobilize-status-circle {
                    min-width: 12px;
                    min-height: 12px;
                    background-color: #FF0000;
                    border-radius: 100%;
                    margin-right: 12px; } }

            .mobilize-at {
                display: flex;
                justify-content: flex-start;
                text-align: left;
                margin-top: 10px;
                color: var(--mdc-theme-text-secondary-on-light); } } }
    .contract-end-time {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        text-decoration-line: underline;
        color: #12324F; }
    .contract-end-time-changed {
        font-weight: 400;
        font-size: 11px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #9AA2B0;
        text-align: center; }

    .custom-dialog {
        .custom-dialog-content {
            .footer-btn {
                button {
                    margin: 0 10px;
                    width: 250px; } } } }
    .dialog-work-to {
        max-width: 546px;
        margin: 0 2px;

        .dialog-work-to-description {
            padding: 28px 55px 21px;
            font-weight: 400;
            font-size: 12px;
            line-height: 129.69%;
            text-align: center;
            letter-spacing: 0.3px;
            color: #12324F; }
        .dialog-work-to-headline {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.3px;
            padding-bottom: 8px; }
        .dialog-work-to-field {
            width: 100%;
            padding-bottom: 21px;
            .dialog-work-to-headline {
                color: #12324F; }
            .dialog-work-to-change {
                width: 100%; }
            .dialog-work-to-error {
                padding: 2px 8px 0px 8px;
                font-weight: 400;
                font-size: 13px;
                line-height: 21px;
                letter-spacing: 0.3px;
                color: #ED0000; } }
        .dialog-work-to-field-block {
            pointer-events: none;
            .dialog-work-to-headline {
                color: #9D9D9D; }
            .dialog-work-to-change {
                opacity: 0.6;
                height: 44px;
                border-radius: 4px;
                display: inline-flex;
                overflow: hidden;
                width: 100%;
                padding: 12px 16px;
                border: 1px solid rgba(0, 0, 0, 0.38);
                color: rgba(0, 0, 0, 0.87); } }
        .dialog-work-to-checkbox {
            display: flex;
            align-items: center;
            padding-bottom: 30px;
            .dialog-work-to-checkbox-text {
                padding-left: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #12324F; }
            .off-checkbox {
                .mdc-checkbox__background {
                    background-color: #Ffffff !important; } } } } }


@media (max-width: 1246px) , (max-height: 720px) {
    .mobilization-page {
        .mobilize-status {
            .table-cell-inner {
                .mobilize-status-wrapper {
                    font-size: 12px;
                    .mobilize-status-circle {
                        min-width: 10px;
                        min-height: 10px;
                        margin-right: 5px; } } } }
        .period {
            .text {
                font-size: 15px; } } } }
