.accordion {
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px; }

    &-title {
        font-weight: 700;
        font-size: 22px;
        color: #12324F; }

    &-arrow {
        color: #12324F;
        font-size: 30px;
        position: relative;
        left: 10px;

        &--active {
            transform: rotate(-180deg); } } }
