.pending-review-page {
    padding: 24px;

    &-review-card {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding: 15px 0;

        &:not(:last-child) {
            margin-bottom: 15px; } }

    &-form {
        display: flex;
        align-items: center;
        margin-bottom: 20px; }

    &-textfield {
        width: 100%;
        margin-right: 15px; }

    &-button {
        text-transform: uppercase;
        min-width: 180px; } }
