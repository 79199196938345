.needs-analytics-page {
  .organization-name {
    color: var(--mdc-theme-text-primary, #354C62);
    text-decoration: underline; }
  .organization-status {
    display: flex;
    align-items: center;
    width: 100%;
    .organization-status-color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 10px;
      flex-basis: 12px; }
    .organization-status-text {
      text-align: start;
      margin-left: 20px; } }
  .organization-active-modules {
    display: flex;
    .organization-module-type {
      margin: 0 5px; } } }
