.worker-card-page {

  .worker-card-wrapper {
    // padding: 1rem

    .worker-card-inner {

      .form-block:nth-of-type(2) .form-column--item {
        min-height: 70px; }

      .section {
        margin-bottom: 1rem; } } }

  // .worker-card-requisites-wrapper
  //     border-bottom: 1px solid #E1E1E7

  //  .infoBlock-wrapper
  //      margin-bottom: 1rem

  .report-message {
    display: flex;
    align-items: center;
    margin-top: 20px;
    max-width: 350px;
    font-weight: 500;

    .report-icon {
      margin-right: 15px;
      color: #CC9900; } }

  .contract-status {
    .contract-status-headline {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .contract-status-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #5C6E8C; }
      .contract-status-icon {
        cursor: pointer;
        margin-left: 10px; } }

    .contract-status-body {
      display: flex;
      align-items: center;
      .contract-status-circle {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 10px; }
      .contract-status-current {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #9AA2B0; } } } }
