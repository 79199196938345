.contract-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    padding: 12px 20px;
    color: #5C6E8C;
    height: 100%;
    cursor: pointer;

    & &-date-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px; }

    & &-date {
        font-weight: 500; }

    & &-position {
        font-size: 20px;
        font-weight: 700;
        color: #173451;
        margin-bottom: 15px; }

    & &-number {
        margin-bottom: 5px; }

    & &-organisation {
        margin-bottom: 20px; }

    & &-period {
        margin-bottom: 20px;

        & > * {
            color: #5C6E8C;
            font-size: inherit;
            font-weight: 400; } } }
