.table-row {
    display: flex;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: white;
    min-height: 96px;
    min-width: 100% !important;
    &.heading {
        min-height: 75px !important;
        background-color: white; }
    &.disabled {
        filter: brightness(95%); }

    // .row

    //     background: white
    //     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
    //     margin-bottom: 1rem
 }    //     height: 96px
