.request-field {
    max-width: 500px;

    &-title {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #5C6E8C;
        margin-bottom: 4px; }

    &-value {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #12324F;
        word-break: break-word; } }
