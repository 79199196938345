.request-card {
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); }

    & &-publish-date {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #5C6E8C;
        margin-bottom: 10px; }

    & &-position {
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
        color: #12324F;
        margin-bottom: 4px; }

    & &-salary {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #5C6E8C;
        margin-bottom: 5px; }

    & &-city {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #5C6E8C;
        margin-bottom: 4px; }

    & &-organisation {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #5C6E8C;
        margin-bottom: 12px; }

    & &-need-field {
        margin-bottom: 13px;

        &-label {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: #5C6E8C; }

        &-value {
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: #12324F; } }

    & &-shedule, &-responsibilities {
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #12324F;
        overflow-wrap: break-word;
        margin-bottom: 12px; }

    & &-responsibilities {
        margin-bottom: 20px;

        &-no-responsed {
            margin-bottom: 12px; } }

    & &-responde-at {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #5C6E8C;
        margin-bottom: 20px;
        position: relative;

        &-rejected {
            margin-bottom: 0; }

        &::after {
            content: '';
            position: absolute;
            left: -16px;
            bottom: 0;
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-bottom: 7px solid #F2994A;
            border-left: 7px solid #F2994A;
            border-bottom-left-radius: 2px; } }

    & &-button {
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin-top: auto;

        &-outlined {
            border: 2px solid #E1E1E7;
            background-color: #F5F5F8;
            color: #12324F;
            box-shadow: none; }

        &-circular-progress-white {
            color: #fff; } } }
