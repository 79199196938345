.documents-table {
  min-width: 750px !important;

  .documents-table-cell-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3px; }

  .documents-table-cell-date {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center; }

  .modal-title {
    background-color: var(--mdc-theme-secondary);
    display: flex;
    padding: 9px 24px 9px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: unset;

    i {
      cursor: pointer; } }

  .modal-content {
    color: unset;
    padding: 0;

    hr {
      color: #d8dee1;
      border: 1px solid; }

    > .footer-btn {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      > button {
        width: 50%; } }

    > .doc-container {

      display: flex;
      min-height: 180px;
      flex-wrap: wrap;
      width: 750px; } }


  .doc-file {
    min-height: 180px;
    width: 150px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    align-items: center;
    height: min-content;

    &.download {
      cursor: pointer; }

    > .pic {
      background-color: var(--mdc-theme-background);
      margin: .5rem;
      border: 2px solid #d8dee1;
      flex: 1 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      height: 110px;
      width: 110px;
      max-height: 110px;
      position: relative;

      > .close {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer; } }

    > span {
      text-align: center;
      display: block;
      font-weight: 400;
      word-break: break-word; } }

  .dropzone {
    border: 3px dashed #9ca7aa;
    width: 750px;
    height: 220px;
    background-color: var(--mdc-theme-background);
    margin: 15px 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    position: relative;

    > i {
      font-size: 9rem; }

    > div {
      font-size: 1.2rem;
      font-weight: 600; }

    > input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;

      &:hover {
        cursor: pointer; } } }

  .dropzone:hover {
    opacity: 1;
    cursor: pointer; }

  .dropzone.dragover {
    opacity: 1; }

  .tooltip {
    max-width: 220px;
    word-break: break-word; }

  .doc-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: 45px;
    padding: 0px 12px;
    text-align: left; }
  // overflow: hidden

  .doc-name {
    display: flex;
    max-width: 80%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word; }

  .doc-extension {
    align-self: flex-end; }

  .table-wrapper {
    //margin-top: 20px
    position: relative;
    width: 100%;
    //height: calc(100% - 150px)
    //overflow: auto

    .documents-table-component {
      margin-top: 0px;
      overflow: auto;

      .documents-table-header {
        display: flex;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        z-index: 2;
        position: sticky;
        top: 0;
        height: 80px;

        .devider:not(:last-of-type) {
          border-right: 0.5px solid #DEE7EF; }

        .documents-table-header-item {
          display: block;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
          padding: 10px;
          text-overflow: ellipsis !important;
          background: #FFFFFF;

          .documents-table-header-inner {
            width: 170px;
            display: flex;
            text-align: left;
            min-width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-overflow: ellipsis !important;
            overflow: hidden !important;

            .name {
              display: flex;
              text-align: left;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 500;
              color: #12324F; }

            .expands {
              display: flex;
              flex-direction: column;
              margin-left: 5px;

              .icon {}

              .expand {
                cursor: pointer;
                height: 20px;

                &:first-child {
                  margin-bottom: 10px; } }

              .expand-active {
                color: red; } } } } }

      .documents-table-cell {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis; }

      .icon-buttons {
        width: 60px;
        display: flex;
        justify-content: space-between;

        i {
          cursor: pointer; } } } } }

