.qualification-documents-field-item {
  margin-bottom: 16px; }

.qualification-documents-number-field {
  padding: 12px;
  width: 100%; }

.qualification-documents-image-field {
  width: 208px;
  height: 208px;
  border: 2px solid #E1E1E7;
  padding: 24px;
  border-radius: 2px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

  .image-input {
    width: 160px;
    height: 160px;
    background: #F5F5F8;
    border: 2px solid #E1E1E7; } }
