.setting-professions-list {
  overflow: hidden;
  height: calc(100vh - 210px);

  .professions-top {
    .sorted-value {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 35px;
      padding-bottom: 5px;

      .sort {
        display: flex;
        padding: 5px 8px;
        height: 34px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.14);
        margin: 5px;

        .sorted-item {
          display: flex;

          .column_name {
            margin-right: 5px; }

          .type {
            max-width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 5px;
            white-space: nowrap; } }

        .cancel {
          cursor: pointer; } } }

    .buttons {
      width: 200px;
      margin-left: auto; } }

  .custom-table-wrapper {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1) !important;
    margin-top: 20px;
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    height: calc(100vh - 400px);
    overflow: auto;

    .custom-table {
      margin-top: 0px;
      position: relative;

      .circular-progress {
        margin: 0 auto; }

      .custom-table-header {
        display: flex;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        z-index: 2;
        position: sticky;
        top: 0;
        height: 80px;

        .devider:not(:last-of-type) {
          border-right: 0.5px solid #DEE7EF; }

        .custom-table-header-item {
          display: block;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
          padding: 10px;
          text-overflow: ellipsis !important;
          background: #FFFFFF;

          .custom-table-header-inner {
            width: 170px;
            display: flex;
            text-align: left;
            min-width: 100%;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            text-overflow: ellipsis !important;
            overflow: hidden !important;

            .name {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 500;
              color: #12324F;
              flex-basis: 95%; }

            .expands {
              display: flex;
              flex-direction: column;
              flex-basis: 20px;

              .expand {
                cursor: pointer;
                height: 20px;

                &:first-child {
                  margin-bottom: 10px; } }

              .expand-active {
                color: red; } } } } }

      .custom-table-cell {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis; }

      .icon-buttons {
        width: 60px;
        display: flex;
        justify-content: space-between;

        i {
          cursor: pointer; } } } }

  .professions-bottom {
    flex: 0 1 auto;

    .buttons {
      display: flex;
      width: 700px; }

    .button {
      max-width: 200px; } }

  .icons {
    cursor: pointer; } }
