.update-history-dialog {

  .update-history-dialog-content {
    display: flex;
    flex-direction: column;
    width: 942px;
    height: 864px;

    .update-history-dialog-wrapper {
      .update-history-dialog-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #12324F;
        justify-content: center;
        padding-bottom: 10px;
        height: 88px;
        text-overflow: ellipsis;
        overflow: hidden; }

      .update-history-dialog-body {
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        letter-spacing: 0.3px;
        color: #5C6E8C;

        .update-history-dialog-body-text {
          overflow: auto;
          max-height: 545px;
          padding: 24px;
          border: 1px solid #E1E1E7;
          border-radius: 9px; }

        .update-history-dialog-body-image {
          width: 895px;
          height: 660px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain; } } }

    .update-history-dialog-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px 60px 15px;
      margin-top: auto;
      width: 100%;
      transition: width 0.3s ease-in-out;

      .update-history-dialog-footer-points {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .update-history-dialog-footer-count {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.3px;
          color: #9AA2B0;
          margin-bottom: 16px; }
        .update-history-dialog-footer-points-wrapper {
          display: flex;
          flex-direction: row; }
        .update-history-dialog-footer-point {
          width: 8px;
          height: 8px;
          background: #9AA2B0;
          border-radius: 50%;
          align-self: center;
          margin: 0 4px;
          transition: height, width, margin 0.3s ease-in-out; }

        .point-active {
          width: 10px;
          height: 10px;
          background: #12324F; } }

      .update-history-dialog-footer-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 18px;
        .update-history-dialog-footer-btn {
          width: 200px; } } }

    .update-history-dialog-footer-center {
      width: 320px;
      margin: auto auto 0 auto; }

    @media screen and (max-width: 1300px) {
      .update-history-dialog-wrapper {
        .update-history-dialog-body {
          .update-history-dialog-body-text {
            max-height: 435px;
            padding: 16px; } } } } } }
