.analytics-page {
  .analytics-items {
    .analytics-item {
      background-color: #Ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 16px;
      margin-bottom: 12px;

      .analytics-item-name {
        flex-basis: 35%;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #12324F; }

      .analytics-item-date {
        flex-basis: 45%;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #9AA2B0; }

      .analytics-item-download {
        flex-basis: 6%;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        padding-right: 16px;
        text-decoration-line: underline;
        color: #5C6E8C;
        cursor: pointer; }

      .analytics-item-nofile {
        flex-basis: 6%; }

      .analytics-item-btn {
        flex-basis: 14%;

        button {
          width: 220px;
          float: right; }

        .analytics-item-btn-inner {
          display: flex;
          align-items: center;

          .analytics-item-btn-text {}

          .analytics-item-btn-loader {
            position: relative;
            width: 35px; }

          i {
            margin-left: 10px; } } } } }

  .lds-spinner {
    color: white;
    display: inline-block;
    position: absolute;
    top: -16px;
    left: -11px;
    width: 55px;
    height: 35px;

    div {
      transform-origin: 38px 16px;
      animation: lds-spinner 1.2s linear infinite;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 3px;
        height: 8px;
        border-radius: 10%;
        background: #fff; }

      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s; }

      &:nth-child(2) {
        transform: rotate(45deg);
        animation-delay: -1s; }

      &:nth-child(3) {
        transform: rotate(90deg);
        animation-delay: -0.9s; }

      &:nth-child(4) {
        transform: rotate(135deg);
        animation-delay: -0.8s; }

      &:nth-child(5) {
        transform: rotate(180deg);
        animation-delay: -0.7s; }

      &:nth-child(6) {
        transform: rotate(225deg);
        animation-delay: -0.6s; }

      &:nth-child(7) {
        transform: rotate(270deg);
        animation-delay: -0.5s; }

      &:nth-child(8) {
        transform: rotate(310deg);
        animation-delay: -0.4s; } }

    @keyframes lds-spinner {
      0% {
        opacity: 1; }
      100% {
        opacity: 0; } } } }
