.reviews {

  &.non-elevate {
    box-shadow: none; }

  > .review {
    display: flex;
    border-top: 1px solid #E1E1E7;

    &:first-child {
      border: none; }

    > .span-buttons {
      margin-left: auto;
      display: flex;
      align-items: baseline; }

    > div {
      padding: 1.3rem;
      min-width: 170px;

      > span {
        color: var(--mdc-theme-text-secondary-on-light); }

      > .raiting {
        margin: 0.3rem 0 1rem; }

      > .employment-time {
        white-space: pre-line; }


      > .author-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        > .role {
          margin-top: 0.3rem;
          font-weight: 600; }

        > .name {
          color: var(--mdc-theme-text-secondary-on-light); } }

      > .comment {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-line;
        font-weight: 600; } } } }

.add-review {
  padding-bottom: 10px;
  margin-bottom: 0.5rem;
  .btn-panel-list {
    display: flex;
    margin-top: 4px;
    margin-bottom: 10px;
    list-style: none;

    .btn-panel-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;

      .btn {
        min-width: 256px;
        padding: 6px 12px;
        border-radius: 0;
        border: none;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.6px;
        cursor: pointer; } } }


  > .stars {

    display: flex;
    padding: 0.5rem 0 0 1rem;
    cursor: pointer;

    > .star {

      position: relative;

      > .left-trigger {
        height: 48px;
        width: 24px;
        position: absolute; }

      > .right-trigger {
        height: 48px;
        width: 24px;
        position: absolute;
        left: 24px; } } }

  > .text-area {

    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem;

    & * {
      overflow: hidden; }

    > label {
      display: flex;
      padding-left: 6px;

      > textarea {
        resize: none !important;
        line-height: 28px; } } }

  .mdc-button {

    margin-left: 1rem;
    margin-bottom: 1rem;
    height: 34px !important; } }

.link-to-public-profile {
  font-weight: bold;
  // text-decoration: none
  font-size: 18px;
  color: var(--mdc-theme-text-primary); }
