.employment-process-step {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #5C6E8C;

    &-info {
        display: flex;
        flex-direction: column;
        margin-left: 18px; }

    &-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #12324F;
        margin-bottom: 12px;

        &-no-body {
            margin-bottom: 0;
            margin-top: 3px; } }

    &-body {
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #12324F; } }
