.request-page {
    & &-info-card {
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        padding: 19px;
        display: flex;
        align-items: center;
        margin-bottom: 22px;

        &-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%; }

        &-message {
            display: flex;
            align-items: center; }

        &-icon {
            margin-right: 13px;
            font-size: 24px; }

        &-text {
            font-weight: 500;
            font-size: 16px;
            margin-top: 2px; }

        &-responde-time {
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.3px;
            color: #5C6E8C; } }

    & &-card {
        background-color: #fff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 32px;
        margin-bottom: 20px;

        &-table {
            display: flex;

            &-col:first-child {
                margin-right: 100px;
                flex-shrink: 0; } }

        &-divider {
            margin: 28px 0; } }

    & &-progress {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;

        &-link {
            display: inline-block;
            font-weight: 400;
            font-size: 24px;
            text-decoration: underline;
            color: #12324F; } }

    &-arrow {
        font-size: 16px;
        position: relative;
        top: 2px;
        left: 5px; }

    & &-field {
        display: block;

        &-link {
            text-decoration: underline; }

        &:not(:last-child) {
            margin-bottom: 28px; } }

    & &-actions {
        display: flex;
        grid-gap: 15px; }

    & &-button {
        text-transform: inherit;
        max-width: 357px;
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.3px;
        border-width: 2px;

        &-progress {
            color: #fff; } } }
