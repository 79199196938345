.workers-page {
  .worker-name {
    color: var(--mdc-theme-text-primary, #354C62);
    text-decoration: underline;
    cursor: pointer; }
  .worker-status {
    display: flex;
    align-items: center;
    width: 100%;
    .worker-status-color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 10px;
      flex-basis: 12px; }
    .worker-status-text {
      text-align: start;
      margin-left: 20px; } } }
