.garments-page-wrapper {
    background: white;
    padding: 24px;
    display: flex;
    justify-content: space-between;

    .garments-page-list {
        display: flex;
        flex-direction: column;
        flex: 1; } }



