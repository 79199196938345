.dialog-settings {
  position: absolute;
  right: 180px;
  top: 110px;
  z-index: 5;
  transform: translateZ(100px);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  background-color: #ffffff;
  align-items: start;
  padding: 15px 15px 15px 15px;
  justify-content: center;
  text-align: start;
  font-weight: 400;
  color: #354c62;
  width: 300px;

  .dialog-items {
    height: 420px;
    border: 1px solid #DAE6F6;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    overflow: auto;

    .dialog-switch {
      border-top: 1px solid #DAE6F6;
      padding: 6px 0;

      &:first-child {
        border: none; } } }

  .dialog-button {
    display: flex;
    cursor: pointer;
    width: 100%;
    border-top: 2px solid rgba(0, 68, 180, 0.5);
    margin-top: 10px;
    padding: 10px 50px 5px 50px;

    div {
      padding-top: 1px; }

    .icon {
      margin-right: 10px; }

    &:hover {
      color: #0044B4; }

    &:active {
      color: #003fa8; } } }
