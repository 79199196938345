.span-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    // flex: 1 1 0
    grid-gap: 20px;

    .span-a:not(:last-of-type) {
 } }        // margin-right: 2rem
