.workers-new-page {
  min-width: 750px !important;
  height: 100%;

  .module-blocked:after {
    margin-top: 440px; }

  .worker-row {
    min-height: 70px;


    .table-cell {
      padding: 5px 5px 10px 5px; } }

  .workers-top {
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;

    .filtered_or_sorted_items {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 92px;
      padding-bottom: 5px;
      overflow: auto;

      .filtered_or_sorted_item {
        display: flex;
        padding: 5px 8px;
        height: 34px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.14);
        margin: 5px;

        .item {
          display: flex;

          .column_name {
            margin-right: 5px; }

          .type {
            max-width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 5px;
            white-space: nowrap; } }

        .cancel {
          cursor: pointer; } } }

    .search_field {
      position: relative;

      .search {
        position: absolute;
        top: 10px;
        right: 10px; } }

    .buttons {
      position: relative;
      display: flex;
      width: 825px;
      margin-left: auto;

      .restart_settings {
        margin-top: 1rem;
        margin-right: 10px; } } }

  .table-cell-inner {
    width: 170px; }

  .search-input {
    width: 500px; }

  .table-wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: calc(100% - 150px);
    overflow: auto;
    @media screen and (max-width: 1370px) {
      height: calc(100% - 160px); } }

  .workers-table-pagination {
    display: flex;
    margin-top: 10px;
    .pagination-btn {
      display: flex;
      background-color: #FFFFFF;
      padding: 5px 6px;
      margin: 0 5px;
      box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      cursor: pointer;
      &:first-child {
        div {
          padding-right: 6px; } }
      &:last-child {
        div {
          padding-left: 6px; } } } }


  .workers-table {
    margin-top: 0px;

    .headings {
      // background: var(--mdc-theme-secondary)
      z-index: 2;
      position: sticky;
      top: 0;
      height: 80px;
      // min-width: 100% !important
      .table-cell {
        .table-cell-inner {} } }

    .table-row.worker-row {
      height: 100%;
      min-width: auto !important;
      // min-width: auto !important
      .table-cell {
        text-align: center;

        .profile-link {
          color: var(--mdc-theme-primary); } } } } }
