.custom-dialog {
  .custom-dialog-title {
    background-color: var(--mdc-theme-secondary);
    display: flex;
    padding: 9px 24px 9px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);

    i {
      cursor: pointer; } }
  .custom-dialog-content {
    max-width: 700px;
    max-height: 550px;
    color: unset;

    hr {
      color: #d8dee1;
      border: 1px solid; }

    .footer-btn {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;

      button {
        width: 50%; } }

    .custom-dialog-container {
      display: flex;
      min-height: 80px;
      max-height: 500px;
      flex-wrap: wrap;
      justify-content: center;
      overflow: auto; } } }
