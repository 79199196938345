.wrapper {
  display: flex;
  background: #DEE7EF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 16px;
  gap: 16px;
  position: relative;

  .clickable {
    text-decoration: underline;
    cursor: pointer; }

  .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .rmwc-icon {
      font-size: 20px !important;
      display: block !important;
      width: 20px !important;
      height: 20px;
      text-decoration: none !important; } }
  .tariff-elements {
    display: flex;
    gap: 8px; }
  .tariff-element,
  .history-wrapper {
    display: flex;
    align-items: center;
    color: #12324F;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.3px;
    width: inherit !important;

    .tariff-element-title {
      margin-right: 8px; }

    .tariff-info-element {
      text-decoration: none !important; } }

  .paid-vacancies-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 5px; }

  .paid-vacancies-wrapper {
    // position: relative
    cursor: pointer;
    width: inherit;
    .paid-vacancies-description {
      cursor: default;
      width: inherit;
      left: 0;
      // right: -32px
      top: 58px;
      z-index: 6;
      background: #FFFFFF;
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
      .paid-vacancies-description-close {
        margin-left: auto;
        cursor: pointer;
        padding: 8px 8px 0 0; }
      .paid-vacancies-description-text {
        text-align: center;
        letter-spacing: 0.3px;
        padding: 0 40px 18px;
        a {
          color: #12324F;
          text-decoration: underline; } }
      .paid-vacancies-description-button {
        margin-bottom: 30px; } } }

  .mdc-dialog__surface {
    max-width: 545px !important; }

  .paid-vacancies-balance-dialog-body {
    margin: 40px 0;
    color: #12324F;
    font-size: 16px;
    text-align: center; } }

.rmwc-tooltip {
  opacity: 1;
  .rmwc-tooltip-content {
    margin-top: 20px;
    margin-left: -16px;
    max-width: 513px !important;
    .rmwc-tooltip-inner {
      font-family: "Roboto";
      background: white !important;
      border: none !important;
      padding: 16px;
      font-size: 14px !important;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: rgba(92, 110, 140, 1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: none; } } }

.rmwc-tooltip-zoom-enter,
.rmwc-tooltip-zoom-appear {
  opacity: 0; }

.tariff-cards {
  .mdc-dialog {
    .mdc-dialog__container {
      min-width: 950px !important;
      .mdc-dialog__surface {
        min-width: 100% !important;
        .tariff-cards-layout {
          justify-content: center;
 } } } } }          // min-width: 100% !important

