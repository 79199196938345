.update-history {


  .update-history-body {
    .update-history-item {
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
      padding: 16px 20px;

      .update-history-item-name {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #12324F;
        overflow: hidden;
        text-overflow: ellipsis; }

      .update-history-item-body {
        padding: 24px 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #5C6E8C;
        overflow: hidden;
        text-overflow: ellipsis; }

      .update-history-item-bottom {
        display: flex;
        align-items: center;

        .update-history-item-version {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #5C6E8C;
          margin-right: 10px; }

        .update-history-item-date {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #9AA2B0; }

        .update-history-item-button {
          width: 234px;
          height: 40px;
          margin-left: auto; } } } } }
