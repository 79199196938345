.versions-history-redact {
  .versions-history-redact-top {
    margin-bottom: 24px;

    .buttons {
      width: 400px;
      margin-left: auto; } }

  .dialog-delete-body {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: auto;
    padding: 20px 0;
    color: #12324F; }

  .versions-history-redact-body {
    background-color: #Ffffff;
    padding: 16px;

    .versions-history-redact-field {
      .versions-history-redact-field-headline {
        padding: 4px 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #5C6E8C; }

      .versions-history-redact-files {
        display: flex;
        flex-wrap: wrap;

        .web-version {
          width: 340px;
          height: 340px;
          flex: 0 1 340px; }

        .mobile-version {
          width: 285px;
          height: 451px;
          flex: 0 1 285px; }

        .versions-history-redact-file {
          position: relative;
          margin: 10px;

          .close {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            z-index: 5;
            color: #2E3A59; } }

        .versions-history-redact-file-loaded {
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain; }
        .zoom_in {
          position: absolute;
          top: 0;
          opacity: 0;
          font-size: 50px;
          justify-content: center;
          align-items: center;
          color: white;
          transition: background, opacity 0.1s ease-in-out;
          cursor: pointer;
          &:hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.35); } }
        .zoom_in_web {
          padding: 145px; }
        .zoom_in_mobile {
          padding: 201px 118px; }

        .versions-history-redact-files-upload {
          background: rgba(222, 231, 239, 0.15);
          border: 3px dashed #E1E1E7;
          display: flex;
          border-radius: 10px;
          align-items: center;
          cursor: pointer;
          position: relative;
          flex-direction: column;

          .versions-history-redact-files-upload-description {
            padding-top: 40px;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.3px;
            color: #E1E1E7;
            flex: 1 0 45%;
            text-align: center;

            div {
              padding: 4px; } }

          i {
            display: flex;
            align-items: end;
            color: #DEE7EF;
            flex: 1 0 55%; }

          input {
            position: absolute;
            z-index: 10;
            opacity: 0;
            height: 100%;
            width: 100%;
            cursor: pointer; } }

        .web-version-upload {
          width: 340px;
          height: 340px;
          flex: 0 1 340px; }

        .mobile-version-upload {
          width: 266px;
          height: 444px;
          flex: 0 1 266px; } } }

    .versions-history-redact-field-textfield {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #12324F; }

    textarea {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #5C6E8C !important; }

    .mdc-text-field__input {
      margin: 0; }

    .versions-history-view-textfield {
      padding-bottom: 12px;
      padding-top: 4px;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #12324F;
      overflow: hidden;
      text-overflow: ellipsis; }

    .versions-history-view-textarea {
      padding-bottom: 12px;
      padding-top: 4px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: #12324F; } }

  .versions-history-redact-bottom {
    flex: 0 1 auto;

    .buttons {
      display: flex;
      width: 700px; }

    .button {
      max-width: 298px; } } }
