.shift-info {

    &-brigade-name {
        display: inline-block;
        margin-right: 15px; }

    &-foreman {
        color: inherit;
        text-decoration: underline; }

    &-body {
        margin: 0 24px;

        &-item {
            padding: 20px 0; }

        &-worker {
            display: flex;
            justify-content: space-between; }

        &-time-wrapper {
            display: flex; }

        &-time-body {
            margin: 0 10px; }

        &-time-wrapper {
            display: flex;
            align-items: center;
            position: relative;
            right: 11px; }

        &-checkbox {
            filter: grayscale(100%);
            opacity: 0.5; }

        &-time {
            font-size: 22px;
            color: #12324F;
            font-weight: 700; }

        &-field {
            max-width: 100%;

            &-label {
                font-weight: 400;
                font-size: 20px;
                color: #697077;
                margin-bottom: 10px;

                &-extended {
                    margin-bottom: 20px; } }

            &-value {
                font-weight: 700;
                font-size: 22px;
                color: #12324F; } } } }
