.chat {
  height: 100%;
  .title-wrapper {
    max-width: 100%;

    .more_horiz {
      margin-left: auto;
      margin-right: 15px; } }

  .chat-rooms-items {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;

    .chat-rooms-item {
      display: flex;
      align-items: center;
      padding: 8px 20px;
      width: 450px;
      border-bottom: 1px solid #DEE7EF;
      .off-checkbox {
        .mdc-checkbox__background {
          background-color: #Ffffff !important; } }

      .chat-rooms-item-avatar {
        background-color: #FFFFFF;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 50px;
        height: 50px;
        border-radius: 50%; }

      .chat-rooms-item-text {
        display: flex;
        margin-left: 10px;
        height: 100%;
        width: 100%;
        align-items: center;

        .messages-page-item-fullName {
          font-weight: 500;
          font-size: 15px;
          line-height: 15px;
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden; } } } }

  .chat-title-wrapper-avatar {
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 64px;
    height: 64px;
    border-radius: 50%; }


  .chat-head-menu-body {
    position: absolute;
    min-width: 160px;
    width: 220px;
    min-height: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 8px;
    top: 35px;
    right: 20px;
    z-index: 3;

    .chat-head-menu-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .chat-head-menu-item {
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 100%;
        border-radius: 8px;
        padding: 5px 15px 8px 15px;
        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: #f5f5f5; } } } }

  .chat-panel {
    height: calc(100% - 80px);
    width: 100%;
    padding: 20px;

    .chat-list {
      position: relative;
      height: calc(100% - 45px);
      width: 100%;
      padding: 0 90px;
      overflow: auto;

      .chat-list-item {
        width: 100%;

        .chat-message {
          padding: 10px 10px 18px 10px;
          max-width: 510px;
          background-color: #F5F5F8;
          margin-bottom: 14px;

          .chat-message-body {
            position: relative;
            width: 100%;
            min-height: 30px;
            display: flex;
            justify-content: space-between;

            .chat-message-body-content {
              display: flex;
              flex-direction: column;
              padding: 0 5px;

              .chat-message-link-user {
                cursor: pointer;
                width: 100%;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.3px;
                color: #9AA2B0;
                margin-bottom: 5px; }

              .chat-message-body-value {
                display: flex;
                max-width: 455px;

                .chat-message-file-back {
                  min-width: 40px;
                  min-height: 40px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  align-items: center;
                  background: #12324F;
                  display: flex;
                  justify-content: center;
                  margin: 0 10px 5px 0;
                  cursor: pointer;

                  .chat-message-file-icon {
                    color: #FFFFFF; } }
                .chat-message-file-image {
                  position: relative;
                  width: 120px;
                  height: 120px;
                  background-color: #FFFFFF;
                  border: 1px solid var(--mdc-theme-text-secondary-on-light);
                  div {
                    margin: auto;
                    min-height: 110px;
                    min-width: 110px;
                    max-width: 110px;
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain; }
                  .zoom_in {
                    position: absolute;
                    top: 0;
                    opacity: 0;
                    font-size: 50px;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    transition: background, opacity 0.1s ease-in-out;
                    cursor: pointer;
                    padding: 35px;
                    &:hover {
                      opacity: 1;
                      background: rgba(0, 0, 0, 0.35); } } } } }



            .chat-message-body-button {
              cursor: pointer; }

            .chat-message-menu {
              position: absolute;
              min-width: 160px;
              width: 200px;
              min-height: 10px;
              background-color: #FFFFFF;
              box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
              border-radius: 8px;
              z-index: 10;
              bottom: 45px;
              right: 30px;

              .chat-message-menu-items {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .chat-message-menu-item {
                  display: flex;
                  cursor: pointer;
                  align-items: center;
                  width: 100%;
                  border-radius: 8px;
                  padding: 5px 15px 8px 15px;
                  transition: background-color 0.1s ease-in-out;

                  &:hover {
                    background-color: #f5f5f5; } } } }
            .chat-message-menu-top {
              bottom: -85px; } } }

        .left-message {
          position: relative;
          margin-right: auto;
          border-radius: 12px 12px 12px 0;

          .chat-massage-time {
            left: 10px; } }

        .right-message {
          position: relative;
          margin-left: auto;
          border-radius: 12px 12px 0px 12px;

          .chat-massage-time {
            right: 8px;
            i {
              margin-left: 8px; } } }

        .chat-message-text {
          margin-bottom: 5px;
          word-wrap: break-word;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          letter-spacing: 0.3px;
          color: #12324F;
          min-height: 20px; }

        .chat-message-text-delete {
          display: flex;
          align-items: center;
          color: #a6abb4;

          .chat-message-text-delete-text {
            margin-left: 5px; } }

        .chat-massage-time {
          display: flex;
          position: absolute;
          align-items: center;
          color: #9AA2B0;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          //width: 60px
          width: 38px;
          bottom: 8px; } }

      .chat-list-date {
        margin: 0 auto;
        width: 200px;
        text-align: center; }

      .chat-typing-message {
        position: fixed;
        bottom: 75px; } }

    .chat-panel-bottom {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 10px;

      .edit_off {
        margin-right: 10px;
        cursor: pointer; }

      .chat-panel-input {
        width: calc(100% - 40px);
        height: 80px; }

      .chat-file-input {
        display: none; }

      .send {
        margin-left: 10px;
        cursor: pointer; } }
    .chat-panel-is-deleted-user {
      margin: 0 auto;
      width: 160px;
      font-size: 20px;
      font-weight: 600; } }

  .doc-file {
    min-height: 180px;
    width: 150px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    align-items: center;
    height: min-content;

    &.download {
      cursor: pointer; }

    > .pic {
      background-color: var(--mdc-theme-background);
      margin: .5rem;
      border: 2px solid #d8dee1;
      flex: 1 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      height: 110px;
      width: 110px;
      max-height: 110px;
      position: relative;
      .image {
        margin: auto;
        min-height: 110px;
        min-width: 110px;
        max-width: 110px;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain; }

      > .close {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer; } }

    > span {
      text-align: center;
      display: block;
      font-weight: 400;
      word-break: break-word; } }

  .dropzone {
    border: 3px dashed #9ca7aa;
    width: 750px;
    height: 220px;
    background-color: var(--mdc-theme-background);
    margin: 15px 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    position: relative;

    > i {
      font-size: 9rem; }

    > div {
      font-size: 1.2rem;
      font-weight: 600; }

    > input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;

      &:hover {
        cursor: pointer; } } }

  .dropzone:hover {
    opacity: 1;
    cursor: pointer; }

  .dropzone.dragover {
    opacity: 1; }

  .tooltip {
    max-width: 220px;
    word-break: break-word; }

  .doc-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: 45px;
    padding: 0px 12px;
    text-align: left; }
  // overflow: hidden

  .doc-name {
    display: flex;
    max-width: 80%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word; }

  .doc-extension {
    align-self: flex-end; } }
