.organization-profile {
  .tab-btn-title {
    font-size: 18px; }
  .payment-history {
    background-color: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 8;
    top: 55px;
    right: 0;
    .payment-history-empty {
      display: flex;
      height: 200px;
      align-items: center;
      justify-content: center; }

    .payment-history-wrapper {
      padding: 5px 0;
      .payment-history-first-cell {
        display: flex;
        align-items: center;
        flex-basis: 33.33%;
        text-align: center;
        min-height: 59px;
        padding: 10px 20px;
        word-break: break-word; }

      .payment-history-middle-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 33.33%;
        text-align: center;
        min-height: 59px;
        padding: 10px 20px;
        word-break: break-word; }

      .payment-history-last-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 33.33%;
        text-align: center;
        min-height: 59px;
        padding: 10px;
        word-break: break-word; }

      .payment-history-header {
        .payment-history-header-row {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          letter-spacing: 0.3px;
          border-bottom: 0.5px solid #DEE7EF;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #5C6E8C; } }

      .payment-history-body {

        .payment-history-body-row {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #12324F;
          border-bottom: 0.5px solid #DEE7EF; } } } } }
