.avatar {
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.avatar:not(.linkDisabled)::after {
  content: "Перейти в профиль";
  position: absolute;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  transition: opacity 0.2s ease-in-out; }

.avatar:hover::after {
  opacity: 1;
  cursor: pointer; }
