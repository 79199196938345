.employee-training-page {
  .employee-training-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-gap: 12px;
    grid-auto-rows: 1fr;
    justify-content: center;

    .employee-training-item {
      cursor: pointer;
      margin: 6px;
      overflow: hidden;
      transition: box-shadow 0.3s ease-in-out;
      //max-width: 360px
      border-radius: 22px;

      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }

      img {
        width: 100%;
        object-fit: cover; } } } }

