.public-worker-profile-page {
  position: relative;
  .profession-info-item-btn {
    margin-left: auto; }
  .contactEmailConfirmed {
    display: flex;
    margin-top: 5px;

    .check_circle {
      color: #00E23F;
      margin-right: 5px; }

    .info {
      color: #ED0000;
      margin-right: 5px; }

    .confirmed {
      font-weight: 500; } }

  .section {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #5C6E8C; }

  .section:last-of-type {
    border: none !important; }

  .section-title {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #12324F; }

  .telephone,
  .email {
    color: #12324F; }

  .documents-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    .documents-list-versions {
      display: flex;

      .document-wrapper {
        width: 212px; } } }

  .worker-panel {
    display: flex;
    background-color: white;
    margin-bottom: 42px;

    .inner-container {
      display: flex;
      width: 100%;
      padding: 24px;

      .worker-info-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .worker-avatar {
          display: flex;
          width: 163px;
          height: 163px;
          background: white;
          border: 1px solid #E1E1E7;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 1722px;
          margin-right: 60px;

          .avatar-preview {
            width: 100%;
            height: 100%;
            object-fit: cover; } }

        .worker-info-list {
          display: flex;
          list-style: none;
          flex-direction: column;
          padding: 0;
          margin: 0;
          margin-top: -4px;

          .info-item:not(:last-of-type) {
            margin-bottom: 1rem; }

          .worker-info-list-reason-deleted {
            width: 450px;
            overflow-wrap: break-word; }

          .info-item {

            .telephone {
              text-decoration: none;
              cursor: pointer; }

            .email {
              text-decoration: underline;
              cursor: pointer; }

            .rating {
              display: flex;
              align-items: center; }

            .response-state {
              display: flex;
              align-items: center;
              cursor: pointer;
              position: relative;
              overflow: visible;

              .response-state-circle {
                width: 12px;
                height: 12px;
                margin: 0 11px;
                border-radius: 50%; }

              .response-state-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.3px;
                text-decoration-line: underline;
                color: #12324F;
                margin-right: 8px; }

              i {
                cursor: pointer; }

              .responses-list {
                background: #FFFFFF;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
                cursor: default;
                position: absolute;
                left: 0;
                top: 25px;
                padding: 20px;
                z-index: 9;

                .responses-list-line {
                  border: 1px solid black;
                  position: absolute;
                  height: calc(100% - 75px);
                  left: 25px;
                  top: 40px; }

                .responses-item {
                  display: flex;
                  align-items: center;

                  &:nth-child(n+2) {
                    padding-top: 8px; }

                  .responses-item-circle {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin-right: 10px;
                    z-index: 1; }

                  .responses-item-info {
                    width: 161px;

                    .responses-item-info-state {
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 19px;
                      letter-spacing: 0.3px;
                      color: #12324F;
                      padding-bottom: 2px; }

                    .responses-item-info-date {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 14px;
                      letter-spacing: 0.3px;
                      color: #9AA2B0; } } } } }

            .rate,
            .loyalty {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.3px;
              color: #12324F; }

            .loyalty {
              padding-left: 20px;
              background-image: url('../../misc/loyalty-icon.svg');
              background-repeat: no-repeat;
              background-position: left center; } } }

        .btn-panel-list {
          display: flex;
          flex-direction: column;
          margin: 0;
          margin-left: auto;
          margin-top: 4px;
          list-style: none;

          .btn-panel-item:not(:last-of-type) {
            margin-bottom: 16px; }

          .btn-panel-item {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
              min-width: 256px;
              padding: 6px 12px;
              border-radius: 0;
              background-color: #12324F;
              border: none;
              color: #F5F5F8;
              font-weight: 500;
              font-size: 13px;
              line-height: 15px;
              text-align: center;
              letter-spacing: 0.6px;
              cursor: pointer; } } } } } } }

@media screen and (max-width: 1300px) {
  .worker-info-wrapper {
    flex-direction: column;
    align-items: center;

    .worker-avatar {}

    .worker-info-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      flex-direction: row !important;
      margin-bottom: 24px !important;
      justify-content: center;

      .info-item:not(:last-of-type) {
        margin-bottom: 0px !important; }

      .info-item {
        display: flex;
        flex-direction: column; } }

    .btn-panel-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-left: 0 !important;
      padding-left: 0 !important;

      .btn-panel-item:not(:last-of-type) {
        margin-bottom: 0px !important; } } } }
