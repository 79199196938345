.table {
    display: grid;
    margin-top: 10px;
    padding: 0;
    grid-row-gap: 10px;
    grid-column-gap: 2px;
    background-color: var(--mdc-theme-background);
    width: 100%;

    // .row

    //     background: white
    //     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
    //     margin-bottom: 1rem
    //     height: 96px

    // .heading-row
    //     height: 75px

    .cell {
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        background-color: white;
        // min-height: 96px
        font-weight: 500;

        .text {
            text-overflow: ellipsis;
            overflow: hidden; }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 35px; } }


    .cell.link-wrapper {


        .link {
            text-decoration: none;
            position: relative;
            z-index: 1; } }

    .cell.period {
        flex-direction: column;
        justify-content: center;

        .text {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            text-overflow: ellipsis;
            overflow: hidden; }

        .text:first-of-type {
            border-bottom: 1px solid grey; } }

    .cell.name-and-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .status {
            display: flex;
            width: 100%;
            align-items: center !important;
            margin-top: auto;
            color: var(--mdc-theme-text-secondary-on-light);

            // .question
            //     margin-left: auto
            //     display: block
            //     width: 20px
            //     height: 20px
            //     background-image: url('./info.svg')

            .status-circle {
                display: flex;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                margin-right: 11px;
                margin-top: 2px; } } }

    .cell:not(:last-of-type) {
 }        // border-right: 0.5px solid #DEE7EF

    .heading {
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 500;
        color: #12324F; }

    .cell.avatar {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: default; }

    .cell.avatar.avatar-link {
        cursor: pointer; }

    .cell.avatar.avatar-link::after {
        content: "Перейти в профиль";
        position: absolute;
        display: flex;
        opacity: 0;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
        color: white;
        transition: opacity 0.2s ease-in-out; }


    .cell.avatar.avatar-link:hover::after {
        opacity: 1; } }

@media screen and (max-width: 1300px) {
    .table {

        .cell {
            font-size: 13px; } } }

