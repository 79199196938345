.survey-list {

  .survey-list-top {
    .buttons {
      width: 130px;
      margin-left: auto; } }

  .survey-list-body {
    margin-top: 24px;

    .survey-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      cursor: pointer;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
      padding: 16px 20px;

      .survey-list-item-container {
        max-width: 1046px;
        color: #5C6E8C;
        font-size: 16px;

        .survey-list-item-title-container {
          display: flex;

          .survey-list-item-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #12324F;
            margin-right: 16px; } }

        .survey-list-item-roles {
          font-weight: 400;
          line-height: 18px;
          padding: 8px 0; }

        .survey-list-item-description {
          margin-bottom: 15px; }

        .survey-list-item-period {
          font-weight: 400;
          line-height: 18px; } } } } }
