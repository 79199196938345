.about-me-wrapper {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex: 1;


  .about-me-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;

    .about-me-item:not(:last-of-type) {
      margin-bottom: 26px; }

    .about-me-item {
      font-weight: normal;

      .job-status {
        display: flex;
        align-items: center; }

      .job-status-circle {
        display: inline-block;
        margin-right: 10px;
        width: 12px;
        height: 12px;
        background-color: #00E23F;
        border-radius: 100%; } } } }


