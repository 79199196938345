.name-and-status {
    width: 100%;
    height: 100%;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        &.just-name {
            justify-content: center; }
        .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.3px; }

        .status {
            display: flex;
            width: 100%;
            align-items: center !important;
            margin-top: auto;
            color: var(--mdc-theme-text-secondary-on-light);


            .status-circle {
                display: flex;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                margin-right: 11px;
                margin-top: 2px; }

            .question {
                margin-left: auto;
                display: block;
                width: 20px;
                height: 20px;
                background-image: url('./info.svg');
                background-repeat: no-repeat; } } } }

@media screen and (max-width: 1300px) {
    .name-and-status {
        .wrapper {
            .name {
                font-size: 14px;
                line-height: 15px; }

            .status {
                width: 100%;
                font-size: 14px;
                .status-circle {
                    width: 10px;
                    height: 10px;
                    margin-right: 10px; }

                .question {
                    width: 23px;
                    height: 20px; } } } } }
