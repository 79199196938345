.vacancy-card-item {
  background: #FFFFFF;
  height: 198px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }

  .vacancy-card-item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vacancy-card-item-top-info {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.3px;
      color: #9AA2B0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .vacancy-card-item-top-info-btn {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.3px;
        text-decoration-line: underline;
        color: #12324F;
        margin-left: 10px;
        cursor: pointer; } }

    .vacancy-card-item-top-status {
      display: flex;
      align-items: center;
      margin-left: 8px;

      .vacancy-card-item-top-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%; }

      .vacancy-card-item-top-title {
        margin: 0 17px 0 11px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #12324F; } } }

  .vacancy-card-item-body {
    .vacancy-card-item-body-position {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: #12324F;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 21px; }
    .vacancy-card-item-body-author {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #12324F;
      padding: 5px 0 15px; }

    .vacancy-card-item-body-city {
      display: flex;
      align-items: center;
      .vacancy-card-item-body-city-title {
        margin-left: 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #12324F; }
      .vacancy-card-item-body-city-title-none {
        margin-left: 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #9AA2B0; } } }
  .vacancy-card-item-footer {
    height: 44px;
    width: 272px;
    .vacancy-card-item-footer-btn {
      width: 272px; } } }
