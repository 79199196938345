.time-keeping-report-page {
    min-width: 800px;
    width: 100%;
    height: 100%;

    .module-blocked:after {
        margin-top: 430px; }
    // overflow: hidden
    // transform: rotateX(180deg)
    // .report-wrapper,
    // .control-panel
    //  // transform: rotateX(-180deg)
    .control-panel {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        padding: 0 24px 0 0;
        background: white;
        align-items: center;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        margin-bottom: 16px;
        .filters {
            width: 600px;
            .form-component {

                .form-block {
                    width: 100%;
                    box-shadow: none;
                    .form-column {
                        max-width: calc(50% - 20px);
                        max-width: 261px; } } } }
        .btns {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-left: 26px;
            .fixed-buttons-container {
                padding-top: 22px;
                grid-template-columns: 200px 200px !important; } } }
    .headings {
        position: static;
        width: 100%;
        // width: auto
        .heading {
            justify-content: flex-start;
            border-width: 0.5px; } }
    .report-wrapper {
        position: relative;
        width: 100%;
        height: calc(100% - 180px);
        overflow: auto;
        @media screen and ( max-width: 1370px ) {
            height: calc(100% - 250px); } }
    .object,
    .brigade-with-workers {
        grid-row-gap: 0px;
        margin-top: 0px;
        box-shadow: none;
        .object-row,
        .brigade-row,
        .worker-row {
            box-shadow: none;
            justify-content: flex-start;
            min-height: auto; }
        .object-row {
            border-bottom: 0.5px solid rgba(18, 50, 79, 0.15);
            background-color: #DEE7EF !important;
            height: 41px;
            .table-cell.devider:not(:last-of-type) {
                border-right: 0.5px solid rgba(18, 50, 79, 0.15); }
            .object-name {
                // padding-left: 63px
                // min-width: 100% !important
                //min-width: calc(100% - 485px) !important
                align-items: center;
                justify-content: flex-start;
                text-align: left;

                // background-color: #DEE7EF !important
                font-weight: 500;
                font-size: 18px;
                line-height: 160%;
                .table-cell-inner {
                    justify-content: flex-start; } }

            .dropdow-arrow {
                cursor: pointer;
                padding: 0;
                background: #DEE7EF; } }
        .brigade-with-workers {
            .brigade-row {
                height: 41px;
                background-color: white;
                border-bottom: 0.5px solid #DEE7EF;
                .brigade-name {
                    // padding-left: 63px
                    //min-width: calc(100% - 485px) !important
                    align-items: center;
                    justify-content: flex-start;
                    height: 41px;

                    font-weight: 500;
                    font-size: 18px;
                    line-height: 160%;
                    .table-cell-inner {
                        justify-content: flex-start; } }

                .dropdow-arrow {
                    cursor: pointer;
                    padding: 0;
                    background: white; } }
            .worker-row.table-row {
 }                // min-width: auto !important
            .worker-row.dark {
                background: #F5F5F8; }
            .worker-row {
                min-height: 48px;
                height: 100%;
                .table-cell {
                    // display: inline-block
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    border-color: #DEE7EF !important;
                    border-bottom: 0.5px solid #DEE7EF;
                    overflow: hidden;
                    text-overflow: ellipsis !important;
                    .table-cell-inner {
                        overflow: hidden;
                        text-overflow: ellipsis !important;
                        text-align: center; } }
                .table-cell:nth-of-type(-n+3):not(:first-of-type) {
                    .table-cell-inner {
                        text-align: left;
                        // flex-direction: column !important
                        justify-content: flex-start; }
                    // text-overflow: ellipsis
                    // white-space: nowrap
 }                    // text-align: center
                .table-cell:nth-of-type(-n+4):not(:first-of-type) {
                    justify-content: flex-start !important; }
                .time-keeping-cell-warning {
                    background: rgba(237, 0, 0, 0.2);
                    cursor: pointer;
                    height: 100%; }
                .time-keeping-cell {
                    // display: flex
                    flex-direction: column !important;
                    border-bottom: 0.5px solid #C5C5C5;
                    border-right: 0.5px solid #C5C5C5;
                    .table-cell-inner {
                        flex-direction: column !important;
                        justify-content: flex-start; }

                    .absent-or-not {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        border-bottom: 0.5px solid #C5C5C5;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 150%;
                        color: rgba(92, 110, 140, 0.9); }
                    .hours-of-work {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #12324F; } } }

 } }            // width: 100%

    .table-cell {
        padding: 0px;
        // padding: 10px
        .table-cell-inner {
            padding: 10px; } } }

.loader {
    display: none;
    position: absolute;
    width: 200px;
    height: 200px;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
    z-index: 99;
    // display: none
    &.open {
        display: block; } }
.control-panel.blocked {
    pointer-events: none; }
@media screen and ( max-width: 1370px ) {
    .time-keeping-report-page {
        .control-panel {
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: center; }
        .fixed-buttons-container {
            padding: 0 0 25px 0 !important; } } }
