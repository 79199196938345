.pending-review-card {
    font-size: 16px;

    &-table {
        display: flex;

        &-cell {
            padding: 6px; } }

    &-review-stats {
        max-width: 250px;
        width: 100%; }

    &-date {
        color: #5C6E8C; }

    &-work-period {
        color: #9AA2B0; }

    &-profile-review {
        flex: 1 1 auto;

        &-name {
            color: #9AA2B0;
            font-weight: 400; }

        &-role, &-comment {
            font-weight: 600;
            color: #12324F; } }

    &-button {
        font-weight: 600;
        font-size: 16px;
        text-decoration-line: underline;
        color: #12324F;
        background: none;
        border: none;
        cursor: pointer; }

    &-comment-form {
        margin-top: 15px; } }
