.info-block-wrapper {
    .info-block-title {
        font-weight: 400;
        color: var(--mdc-theme-text-secondary-on-light);
        margin-bottom: 4px; }

    .info-block-content {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis !important; } }
