.groups-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .circular-progress {
    margin: 0 auto; }
  .professions-dialog-description {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #12324F;
    flex-direction: column;
    .professions-dialog-item {
      cursor: pointer;
      display: flex;
      font-weight: 500;
      width: 100%;
      font-size: 16px;
      padding: 20px;
      border-bottom: 1px solid #E1E1E7;
      .item-check-radio {
        margin-right: 18px; }
      .item-title {} } }
  .custom-dialog-content {
    padding: 20px 24px 20px 24px; } }
