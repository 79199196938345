.dialog-exit-wrapper {
  display: flex;
  flex-direction: column;
  height: 229px;
  width: 565px;

  .dialog-exit-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    min-height: 90px;
    width: 100%;
    background: #DEE7EF;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px;
    position: relative;

    .icon-close {
      position: absolute;
      cursor: pointer;
      right: 40px; } } }

.dialog-exit-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1; }

.dialog-exit-buttons-wrapper {
  display: flex;
  height: 48px;
  width: 440px;
  margin: 0 auto;

  .dialog-exit-button {
    margin: 0 8px;
    height: 27px !important;
    padding-top: 1px;
    white-space: nowrap;
    display: flex;
    flex: 1;
    font-size: 13px; } }
