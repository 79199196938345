.week-scroller {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 10px;

    > .page-button {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 84px;
        width: 2.8rem;
        margin-bottom: 4px;

        cursor: pointer; }

    > .week-block {
        display: flex;
        flex-direction: column;
        padding-bottom: 4px;

        &:first-child {
            padding-right: 4px; }

        &:not(:first-child):not(:last-child) {
            padding-inline: 4px; }

        &:last-child {
            padding-left: 4px; }

        &.week-block--current {
            background: var(--mdc-theme-secondary);

            > .week-title {
                font-size: 20px;
                font-weight: 700; } }

        > .week-title {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: pre;
            text-align: center;
            height: 4rem;
            font-weight: 500; }

        > .days-container {
            display: flex;
            column-gap: 4px;


            > .day-block {
                display: flex;
                flex-direction: column;
                align-items: center;

                border-radius: 2px;
                padding-block: 4px;
                height: 84px;
                width: 2.8rem;

                cursor: pointer;

                &.day-block--current {
                    > .day-number {
                        background: var(--mdc-theme-secondary);
                        border-radius: 50%; } }

                &.day-block--active {
                    outline: 2px solid var(--mdc-theme-primary); }

                > .day-number {
                    font-weight: 600;
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center; } } } } }
