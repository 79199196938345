.period {
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .text {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        text-overflow: ellipsis;
        overflow: hidden; }

    .text:first-of-type:not(.no-dates) {
        border-bottom: 1px solid grey;
        padding-top: 0px; }

    .text:last-of-type:not(.no-dates) {
        padding-bottom: 0px; } }

@media screen and (max-width: 1300px) {
    .period {

        .text {
            font-size: 16px; } } }

