.find-textfield {
    width: 100%;
    position: relative;

    &-search-icon {
        position: absolute;
        right: 42px;
        top: 9px;
        font-size: 25px; }

    &-filter-indicator {
        position: absolute;
        top: 9px;
        right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: red;
        display: none;

        &--active {
            display: block; } } }
