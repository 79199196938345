.fixed-buttons-container {
    display: grid;
    column-gap: 30px;
    padding-top: 1rem;
    color: #FFFFFF;
    .fixed-button-notification {
        position: absolute;
        right: -6px;
        top: -6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ED0000; } }
@media (max-width: 1246px) , (max-height: 720px) {
    .fixed-buttons-container {
        span {
            font-size: 12px; } } }
