.tariff-cards {
  .mdc-dialog .mdc-dialog__surface {
    background: none; }
  .mdc-dialog__surface {
    box-shadow: none; }
  .tariff-cards-layout {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tariff-card {
      background-color: rgba(255, 255, 255, 1);
      padding: 20px;
      border-radius: 8px;
      width: 308px;

      &:nth-child(2) {
        margin: 0 20px; }

      .tariff-card-item {
        padding-bottom: 20px;

        &:last-child {
          padding-bottom: 0; } }

      .tariff-card-type {
        font-size: 12px;
        font-weight: 700;
        line-height: 26px;
        text-align: center; }

      .tariff-card-label {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        color: rgba(18, 50, 79, 1); }


      .tariff-card-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: start;
        color: rgba(154, 162, 176, 1); }

      .tariff-card-plan {
        display: flex;
        align-items: center;
        flex-direction: column;

        .tariff-card-plan-item {
          display: flex;
          align-items: center;
          width: 135px;

          .tariff-card-plan-item-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding-left: 8px;
            color: rgba(92, 110, 140, 1); }

          &:first-child {
            padding-bottom: 10px; } } }

      .tariff-card-price {
        font-size: 25px;
        font-weight: 400;
        line-height: 36px;
        color: rgba(0, 0, 0, 1);
        text-align: center; }

      .tariff-card-divider {
        border-top: 1px solid rgba(210, 220, 255, 1); }

      .tariff-card-btn {
        padding: 0 9px;

        button {
          width: 100%; } } } } }
