.archive-request-list-page {

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .page-title-component {
            margin-bottom: 0; } }

    &-find-input {
        margin-bottom: 20px; } }
