.paid-vacancies-history {
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 234px;
  z-index: 8;
  top: 61px;
  left: 0;
  .paid-vacancies-history-empty {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center; }

  .paid-vacancies-history-wrapper {
    padding: 5px 0;
    .paid-vacancies-history-first-cell {
      display: flex;
      align-items: center;
      width: 175px;
      text-align: center;
      min-height: 44px;
      padding: 10px 20px;
      word-break: break-word; }

    .paid-vacancies-history-middle-cell {
      display: flex;
      align-items: center;
      width: 280px;
      text-align: left;
      min-height: 44px;
      padding: 10px 20px;
      word-break: break-word; }

    .paid-vacancies-history-last-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      text-align: center;
      min-height: 44px;
      padding: 10px;
      word-break: break-word; }

    .paid-vacancies-history-header {
      .paid-vacancies-history-header-row {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #5C6E8C;
        border-bottom: 0.5px solid #DEE7EF;

        .paid-vacancies-history-date {}

        .paid-vacancies-history-service {}

        .paid-vacancies-history-need {} } }

    .paid-vacancies-history-body {
      height: 179px;
      overflow: auto;

      .paid-vacancies-history-body-row {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #12324F;
        border-bottom: 0.5px solid #DEE7EF;

        .paid-vacancies-history-date {}


        .paid-vacancies-history-service {}

        .paid-vacancies-history-need {
          text-decoration: underline;
          a {
            color: #12324F; } } } } } }
