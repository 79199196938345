.divider {
    height: 100%;
    border-right: 2px solid var(--mdc-theme-text-secondary-on-background,rgba(16,44,69,.54));
    align-items: normal;
    width: 2px;
    margin-right: 16px;
    margin-left: 16px;

    &-horizontally {
        display: flex;
        border-bottom: 0.5px solid #5C6E8C;
        margin: 16px 0; }

    &-no-margin {
        margin: 0; } }
