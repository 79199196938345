.checkbox-option {
    display: flex;
    align-items: center;

    &-label {
        font-weight: 500;
        margin-left: 5px; }

    &-checkbox {

        &--disabled {
            opacity: 0.5; } } }
