.brigade-wrapper {
    margin-top: 15px;
    .brigade-inner {
        display: flex;
        flex-direction: column;
        .brigade-title {
            display: flex;
            min-width: 100% !important;
            align-items: center;
            padding: 11px 20px;
            background: white;
            .brigade-name,
            .brigade-workers-count,
            .brigade-foreman {
                display: flex;
                align-items: center;
                margin-right: 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 22px; }
            .brigade-workers-count {
                padding-right: 10px;
                height: 100%;
                border-right: 1px solid var(--mdc-theme-primary); }
            .brigade-foreman {
                color: var(--mdc-theme-text-secondary-on-background); }
            .dropdown-arrow {
                margin-left: auto !important; } } }
    .workers-wrapper.open {
        display: block; }
    .workers-wrapper {
        display: none;
        .worker-table {
            margin-top: 0px;
            grid-row-gap: 0px;
            overflow: visible !important;
            .table-row.object-and-shift {
                border-bottom: none !important; }
            .table-row {
                min-height: auto !important;
                box-shadow: none !important;
                border-bottom: 0.5px solid #E1E1E7;

                .table-cell {
                    display: flex;
                    align-items: center;
                    padding: 15px 20px;
                    .name-and-status {
                        color: var(--mdc-theme-primary); } } }
            .table-row.object-and-shift,
            .table-row.adress-and-foreman {}
            .table-row {
                overflow: visible !important;
                .object,
                .shift,
                .adress,
                .foreman {
                    .table-cell-inner {
                        align-items: flex-start !important;
                        flex-direction: column !important;
                        .title {
                            margin-bottom: 5px; }
                        .text {
                            font-weight: 600;
                            padding: 8.5px 0px; } } }
                .table-cell {
                    overflow: visible !important; }
                .table-cell-inner {
                    height: auto !important;
                    overflow: visible !important; }
                .select {
                    width: 100% !important; } }
            .table-row.heading {
                background: #F5F5F8;
                margin-bottom: 0px !important;
                .table-cell {
                    padding: 8px 20px; }
                .worker-name {
                    .table-cell-inner {
                        justify-content: flex-start !important; } }
                .start-time,
                .end-time {
                    .table-cell-inner {
                        justify-content: flex-start !important; } }
                .edit {
                    .table-cell-inner {
                        justify-content: flex-end;
                        .control-btn {
                            width: 36px;
                            height: 36px;
                            padding: 5px !important; } }
                    .delete-btn {} } }
            .worker-row {
                .table-cell {
                    padding: 25px 20px; }
                .worker-name {
                    .table-cell-inner {
                        justify-content: flex-start !important; } }
                .tracking {
                    display: flex;
                    padding: 8px 20px; } } } } }
