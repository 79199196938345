.mdc-button {
    height: 44px !important; }

.mdc-checkbox {
    > .mdc-checkbox__background {
        border-color: var(--mdc-theme-on-surface,rgba(16,44,69,.87))!important; } }

.mdc-checkbox--selected {
    > .mdc-checkbox__background {
        background-color: var(--mdc-theme-on-surface,rgba(16,44,69,.87))!important; } }

.mdc-radio .mdc-radio__native-control:checked {
    + .mdc-radio__background {
        > .mdc-radio__outer-circle, .mdc-radio__inner-circle {
            border-color: var(--mdc-theme-primary) !important; } } }

.mdc-snackbar__actions {
    > .mdc-snackbar__action {
        color: white !important; } }

.mdc-text-field {
    &:not(.mdc-text-field--textarea) {
        height: 44px !important; }

    &.mdc-text-field--outlined:not(.mdc-text-field--disabled),
    &:not(.mdc-text-field--disabled) {
        background-color: white; }

    &.mdc-text-field--invalid {
        .mdc-floating-label {
            color: var(--mdc-theme-error) !important; } }

    &.mdc-text-field--textarea {
        > .mdc-text-field__input {
            margin: unset; } }

    .mdc-floating-label {
        color: grey !important; }

    .mdc-floating-label--float-above {
        transform: translateY(-28.75px) scale(0.75) !important; }

    > .mdc-line-ripple {
        display: none; }

    > .mdc-text-field__input {
        padding-block: 12px !important; }

    .mdc-text-field__input {
        caret-color: var(--mdc-theme-primary) !important; }

    &.mdc-text-field--invalid {
        border-color: var(--mdc-theme-error);

        > .mdc-floating-label {
            color: var(--mdc-theme-error) !important; } }

    > input:-webkit-autofill ~ .mdc-floating-label {
        transform: translateY(-28.75px) scale(0.75) !important; } }


.rmwc-icon {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none; }

.mdc-list-divider {
    list-style: none; }

div [class*=mdc-elevation--] {
    background-color: white; }

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    padding-bottom: 0 !important; }

.mdc-select {
    height: 44px;

    > .mdc-select__anchor {
        height: inherit;

        > .mdc-select__selected-text {
            height: inherit; }

        > .mdc-select__dropdown-icon {
            background: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E) no-repeat center !important;
            top: 10px;
            bottom: 0; }

        > .mdc-select__selected-text {
            padding-top: 8px; } }

    &.mdc-select--activated {
        > .mdc-select__anchor {
            > .mdc-select__dropdown-icon {
                transform: rotate(-180deg);
                transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1); } } } }

.mdc-dialog .mdc-dialog__surface {
    max-width: unset !important; }

.mdc-list-divider {
    width: 100%; }

.mdc-button--outlined:not(:disabled) {
    border-color: var(--mdc-theme-primary) !important; }

.mdc-form-field {
    label {
        margin-left: 10px; }
    .mdc-switch--checked {
        .mdc-switch__thumb {
            background-color: #0044B4;
            border-color: #0044B4; }
        .mdc-switch__track {
            background-color: #6D9ADC; } }

    .mdc-switch {
        &:not(.mdc-switch--checked) {
            .mdc-switch__track {
                background-color: #DEE7EF; }
            .mdc-switch__thumb {
                background-color: #9AA2B0;
                border-color: #9AA2B0; } } } }
