.survey-list-page {


  .survey-list-page-body {
    .survey-list-page-item {
      background-color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
      padding: 16px 20px;

      .survey-list-page-item-name {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #12324F;
        overflow: hidden;
        text-overflow: ellipsis; }

      .survey-list-page-item-description {
        padding: 24px 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #5C6E8C;
        overflow: hidden;
        text-overflow: ellipsis; }

      .survey-list-page-item-bottom {
        display: flex;
        align-items: center;

        .survey-list-page-item-dateTo {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #5C6E8C;
          margin-right: 10px; }
        .survey-list-page-item-button {
          width: 234px;
          height: 40px; }

        a {
          margin-left: auto;
          color: whitesmoke; } } } } }
//Feedback
