.edit-mobilize-template-subpage {

    .default-template-btn {
        font-size: 18px;
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer; }

    .mobilize-checkbox-list-wrapper {

        .mobilize-checkbox-list {
            list-style: none;
            padding: 0;
            margin: 0;
            border: 0.5px solid #9AA2B0;

            .row.headings {}

            .row {
                display: grid;
                position: relative;
                grid-template-columns: 120px 120px 1fr 120px;
                height: auto;
                background: white;
                border-bottom: 0.5px solid #9AA2B0;

                .cell {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 0.5rem 1rem;

                    .input {
                        display: flex;
                        width: 100%; } }

                .cell.heading {
                    padding: 1rem; }

                .cell.delete-marked-steps {
                    text-decoration: underline;
                    cursor: pointer; }

                .cell.step-title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    justify-content: flex-start;
                    text-align: left;
 }                    // width: 98%

                .cell.checkbox {
                    color: red; }

                .cell.comments {
                    padding: 1.5rem;
                    flex-direction: column;
                    align-items: flex-start;

                    .title {
                        margin-bottom: 0.5rem; } }

                .cell:not(:last-of-type) {
                    border-right: 0.5px solid #9AA2B0; }

                .cell:not(.dnd-picker) {
                    cursor: default !important; }

                .heading {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px; } } } }

    .btn-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-bottom: 1rem; } }


