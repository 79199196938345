.survey-item {
  .survey-item-top {
    margin-bottom: 24px;
    display: flex;
    justify-content: end;
    .buttons {
      max-width: 400px;
      margin-left: auto;
      .button {
        max-width: 298px; } } }
  .dialog-delete-body {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: auto;
    padding: 20px 0;
    color: #12324F; }

  .survey-item-body {
    background-color: #Ffffff;
    .survey-item-body-redact {
      padding: 16px;
      .survey-item-body-redact-item {
        padding-bottom: 12px;
        &:last-child {
          padding-bottom: 0; }
        .survey-item-body-redact-item-title {
          display: flex;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.3px;
          color: #5C6E8C;
          padding-bottom: 4px;

          .survey-item-body-redact-item-title-notification {
            color: #FF0000;
            margin-right: 3px; } }
        .survey-item-body-redact-item-textarea {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #12324F;
          height: 40px;
          border-radius: 2px; }
        .survey-item-body-redact-item-period {
          display: flex;
          .survey-item-body-redact-item-period-date {
            display: flex;
            flex-direction: row;
            flex: 1 0 50%;
            align-items: center;
            font-weight: 400;
            font-size: 19px;
            line-height: 22px;
            letter-spacing: 0.3px;
            color: #5C6E8C;
            &:last-child {
              padding-left: 12px; }
            .survey-item-body-redact-item-period-date-field {
              border: 1px solid #5C6E8C;
              border-radius: 2px;
              width: 100%;
              color: rgba(0, 0, 0, 0.87);
              margin-left: 12px;
              height: 44px;
              padding: 0 16px;
              font-size: 16px; } } }
        .survey-item-body-redact-item-select {
          .css-1pahdxg-control {
            height: 44px;
            outline: none !important;
            box-shadow: 0 0 0 1px #545454;
            border: 1px solid #545454;
            &:hover {
              box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.87);
              border: 1px solid rgba(0, 0, 0, 0.87); } }

          .select__control {
            height: 44px;
            outline: none !important;
            border: 1px solid #545454;
            &:hover {
              box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.87);
              border: 1px solid rgba(0, 0, 0, 0.87); }

            &:active {
              box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.87);
              border: 1px solid rgba(0, 0, 0, 0.87); } } } } }



    .survey-item-body-view {
      padding: 20px;
      .survey-item-body-view-item {
        padding-bottom: 16px;
        &:last-child {
          padding-bottom: 0; }
        .survey-item-body-view-item-title {
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #5C6E8C;
          padding-bottom: 8px; }
        .survey-item-body-view-item-text {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #12324F; }
        .survey-item-body-view-item-link {
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
          letter-spacing: 0.3px;
          color: #1B7EF3;
          cursor: pointer;
          &:hover {
            text-decoration: underline; } } } } } }
