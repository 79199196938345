.onboarding {
  min-height: calc(100% - 144px);
  .onboarding-body {
    padding-top: 24px;

    .onboarding-field {
      .onboarding-field-headline {
        padding: 4px 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #5C6E8C; }

      .onboarding-files {
        display: flex;
        flex-wrap: wrap;

        .web-version {
          width: 340px;
          height: 340px;
          flex: 0 1 340px; }

        .mobile-version {
          width: 332px;
          height: 514px;
          flex: 0 1 332px; }

        .onboarding-file {
          position: relative;
          margin: 10px;

          .close {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 5;
            cursor: pointer;
            color: #2E3A59; } }

        .onboarding-file-loaded {
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          .zoom_in {
            position: absolute;
            top: 0;
            opacity: 0;
            font-size: 50px;
            justify-content: center;
            align-items: center;
            color: white;
            transition: background, opacity 0.1s ease-in-out;
            cursor: pointer;
            &:hover {
              opacity: 1;
              background: rgba(0, 0, 0, 0.35); } }
          .zoom_in_web {
            padding: 145px; }
          .zoom_in_mobile {
            padding: 232px 141px; } }

        .onboarding-files-upload {
          background: rgba(222, 231, 239, 0.15);
          border: 3px dashed #E1E1E7;
          width: 266px;
          height: 496px;
          display: flex;
          border-radius: 10px;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          .onboarding-files-upload-description {
            padding-top: 40px;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.3px;
            color: #E1E1E7;
            flex: 1 0 45%;
            text-align: center;
            div {
              padding: 4px; } }

          i {
            display: flex;
            align-items: end;
            color: #DEE7EF;
            flex: 1 0 55%; }
          input {
            position: absolute;
            z-index: 10;
            opacity: 0;
            height: 100%;
            width: 100%;
            cursor: pointer; } }

        .web-version-upload {
          width: 340px;
          height: 340px;
          flex: 0 1 340px; }

        .mobile-version-upload {
          width: 266px;
          height: 496px;
          flex: 0 1 266px; } } } } }
