.spoiler {
    &:not(:first-child) {
        margin-top: 10px; }

    &.spoiler--open {
        > .body {
            border-top: 1px solid #E1E1E7; } }

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 4px;
        // max-height: 65px

        > .title {
            margin: 0 0 0 10px;
            font-weight: 500;
            max-width: 60%;
            display: flex;
            // align-items: center
            // flex-wrap: wrap
            padding: 0.5rem 0;



            > .caption {
                display: flex;
                align-items: center;
                color: var(--mdc-theme-text-secondary-on-background);
                margin-left: 8px;
                border-left: 1px solid var(--mdc-theme-text-secondary-on-background);
                min-height: 100%;
                padding-left: 8px; }

            > .text-title {
                max-width: 100%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis; } }

        > .subitem {
            display: flex;
            flex-direction: column;
            width: 20%;
            margin-left: auto;

            > .subtitle {
                color: var(--mdc-theme-text-secondary-on-light);
                font-size: 14px; }

            > .subtext {
                font-weight: 500; } }

        > i {
            margin-right: 8px;
            width: 15%;
            text-align: end; } } }
