.organization-profile-moderator {
  .page-title-component {
    margin: 0; }
  .page-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px; }

  .tab-btn-title {
    font-size: 18px; }

  .payment-history {
    background-color: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 8;
    top: 55px;
    right: 0;

    .custom-dialog-content {
      max-height: none;

      .doc-container {
        max-height: none; } }

    .btn-panel-group {
      display: flex;
      justify-content: flex-end;
      .btn {
        display: flex;
        align-items: center;
        padding: 20px 20px 10px 0;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 400;
        line-height: 19px;
        font-size: 16px;
        letter-spacing: 0.3px;
        color: #12324F; } }

    .payment-history-empty {
      display: flex;
      height: 200px;
      align-items: center;
      justify-content: center; }

    .payment-history-dialog {
      padding-bottom: 38px;
      min-height: 200px;

      .payment-history-divider {
        border-bottom: 0.5px solid #5C6E8C; }

      .payment-history-select {
        padding: 20px 59px 0px;

        .payment-history-headline {
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          color: rgba(18, 50, 79, 1);
          padding-bottom: 10px; }

        .payment-history-select-field {
          width: 421px; } }

      .payment-history-field {
        display: flex;
        flex-direction: column;
        width: 100%;

        &:first-child {
          margin-right: 38px; }

        .payment-history-field-layout {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }

        .payment-history-headline {
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          color: rgba(18, 50, 79, 1);
          padding-bottom: 10px; }

        .payment-history-date {
          height: 38px !important; } } }

    .payment-history-wrapper {
      padding: 5px 0;

      .payment-history-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 33.33%;
        text-align: center;
        min-height: 59px;
        padding: 10px 20px;
        word-break: break-word; }

      .payment-history-header {
        .payment-history-header-row {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          letter-spacing: 0.3px;
          border-bottom: 0.5px solid #DEE7EF;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #5C6E8C; } }

      .payment-history-body {

        .payment-history-body-row {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #12324F;
          border-bottom: 0.5px solid #DEE7EF; } }

      .payment-history-date {
        justify-content: left; }

      .payment-history-price {}

      .payment-history-type {}

      .payment-history-responsible {}

      .payment-history-reason {} } } }

// Новые стили

.payment-history-table-wrapper {
  width: 100%;
  height: calc(100vh - 270px); }

.ag-root-wrapper {

  .ag-header {
    height: auto;

    .ag-header-row {
      border-bottom: 2px solid #f5f5f8; }

    .ag-header-cell {
      padding: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #12324F;
      &:not(:last-of-type) {
        border-right: 2px solid #f5f5f8; }

      .ag-header-cell-label {
        justify-content: center; } } }

  .ag-row {
    border-bottom: 0.5px solid #E1E1E7;
    font-size: 14px; }

  .ag-cell {
    padding: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    white-space: normal;
    text-align: center; }

  &-wrapper {
    width: inherit; }

  .ag-cell-left,
  .ag-cell-left * {
    justify-content: flex-start !important;
    text-align: left !important; }

  .ag-cell-right,
  .ag-cell-right * {
    justify-content: flex-end !important;
    text-align: right !important; }

  .ag-cell-row-group .ag-cell {
    font-size: 16px; }

  .ag-cell-editable {
    cursor: pointer; } }

.payment-history-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px 8px;

  & label {
    width: 100%; }

  .form-field-input-wrapper {
    width: 100%; } }


.payment-history-file {
  // margin-top: 33px

  .payment-history-file-loaded {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 155px;
    height: 155px;
    background-color: #FFFFFF;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--mdc-theme-text-secondary-on-light);

    div {
      margin: auto;
      min-height: 115px;
      min-width: 115px;
      max-width: 115px;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }

    .zoom_in {
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 50px;
      justify-content: center;
      align-items: center;
      color: white;
      transition: background, opacity 0.1s ease-in-out;
      cursor: pointer;
      padding: 52px;

      &:hover {
        opacity: 1;
        background: rgba(0, 0, 0, 0.35); } }

    .close {
      z-index: 9;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px; } }

  .payment-history-file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 155px;
    height: 155px;
    background: #FFFFFF;
    border: 1px solid #9AA2B0;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.1);

    div {
      margin: auto;
      height: 115px;
      width: 115px;
      background: #F5F5F8;
      border: 1px solid #9AA2B0;
      box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.1); }

    i {
      position: absolute;
      top: 0;
      padding: 65px; }

    input {
      position: absolute;
      top: 0;
      z-index: 10;
      opacity: 0;
      height: 100%;
      width: 100%;
      cursor: pointer; } } }

.validation-error-message {
  color: red;
  font-size: 12px; }
