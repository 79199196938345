.worker-profile-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .worker-panel {
    display: flex;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 2px; } }

.inner-container {
  display: flex;
  width: 100%;
  padding: 24px; }

.worker-info-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row; }

.worker-avatar-default {
  display: flex;
  width: 310px;
  height: 176px;
  background: white;
  border: 2px solid #9AA2B0;
  background-image: url('../../../misc/user-avatar-default.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 136px; }

.image-input {
  width: 310px;
  height: 176px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.worker-contacts-lists {
  display: flex;
  flex: 1;
  height: 100%;

  .personal-data {
    max-height: 176px; }

  .worker-contacts-list {
    display: flex;
    flex: 1;
    list-style: none;
    flex-direction: column;
    padding: 0;
    margin: 0 0 0 26px;
    grid-gap: 20px;


    .info-item {

      .telephone {
        text-decoration: none;
        cursor: pointer;
        color: #12324F; }

      .email {
        text-decoration: underline;
        cursor: pointer;
        color: #12324F;
        margin-bottom: 12px; } } } }

.confirm-email-block {
  display: flex;
  align-items: center; }

.confirm-email-text {
  margin-left: 10px;

  .button-save {
    margin-top: 16px; } }

.opacity {
  opacity: 0.5; }

.section-title {
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.3px;
  color: #12324F;


  .avatar-preview {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.dialog-wrapper {
  color: #12324F; }

.icon-button-close-dialog {
  position: absolute;
  right: 0;
  cursor: pointer; }

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  position: relative; }

.page-default-reviews {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500; }

@media screen and (max-width: 1300px) {
  .worker-info-wrapper {
    align-items: center;

    .worker-avatar {}

    .worker-info-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      flex-direction: row !important;
      margin-bottom: 24px !important;
      justify-content: center;

      .info-item:not(:last-of-type) {
        margin-bottom: 0px !important; }

      .info-item {
        display: flex;
        flex-direction: column; } }

    .btn-panel-list {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-left: 0 !important;
      padding-left: 0 !important;

      .btn-panel-item:not(:last-of-type) {
        margin-bottom: 0px !important; } } } }
