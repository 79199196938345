.checkbox-dialog-wrapper {
  display: flex;
  flex-direction: column;
  width: 950px;
  overflow: hidden;
  .off-checkbox {
    .mdc-checkbox__background {
      background-color: #Ffffff !important; } }

  .checkbox-dialog-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    min-height: 90px;
    width: 100%;
    background: #DEE7EF;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px;
    position: relative;

    .icon-close {
      position: absolute;
      right: 40px; } } }

.checkbox-dialog-description {
  margin-left: 42px;
  margin-bottom: 10px; }

.search-wrapper {
  padding: 12px 32px;

  .search {
    width: 100%; } }

.checkbox-dialog-content {
  padding: 40px;
  margin: 0 32px;
  overflow: auto;
  height: 640px; }

.checkbox-dialog-field {
  display: flex;
  margin-bottom: 24px; }


.checkbox-dialog-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 65px;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0; }

.checkbox-dialog-button {
  width: 245px;
  margin-bottom: 16px; }
