
.input-inner {
    display: flex;
    align-items: center; }
.table-cell-inner {
    justify-content: flex-start;
    .delete-btn {
 }        // margin: 0 10px
    .input-wrapper {
        position: relative;
        .input-inner {
            padding: 0px; }
        .tracking_input-error {
            bottom: -9px;
            color: red;
            font-size: 14px;
            // bottom: -25px
 } }            // left: -15px
    .input-wrapper {
        display: flex;
        flex-direction: column;
        padding: 1px;
        width: 100%;
        // padding-right: 20px
        // margin-right: 20px
        display: flex;
        justify-content: flex-start;
        font-size: 1rem !important;
        input,
        .text {
            display: flex !important;
            height: auto !important;
            font-weight: 400 !important;
            font-size: 1rem !important;
            border: none !important;
            border-radius: 4px; }
        input {
            padding: 8px 10px !important;
            outline: 1px solid var(--mdc-theme-primary);
            width: 100%; }
        .input.error {
            outline: 1px solid red;
            // height: auto !important
            // padding: 10px !important
            // font-weight: 400 !important
            // font-size: 1rem !important
            // border: none

            // line-height: 16px
 } } }            // letter-spacing: 0.3px
.tracking.start {
    padding-right: 0px; }
