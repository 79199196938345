.public-profile-page {
    .public-profile-page-status {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.3px;
        text-decoration-line: underline;
        color: #12324F;
        margin-left: auto; }
    .form-header {
        padding: 1.6rem 1.6rem 0 1.6rem;
        background-color: white;
        width: 100%; }
    .form-header {
        display: flex;

        > .avatar-container {
            display: flex;

            > i {
                cursor: pointer;
                font-size: xx-large; } }

        .form-header-moderation-info {
            display: flex;
            margin-left: 20px;

            i {
                width: 24px; }

            span {
                margin-left: 10px;
                max-width: 500px; } }

        > .span-buttons {
            position: absolute;
            top: 1rem;
            right: 1rem; }

        .image-input {
            width: 200px;
            height: 200px;
            position: relative;
            border: 2px solid var(--mdc-theme-text-secondary-on-background);
            border-radius: 4px;

            > * {
                cursor: pointer;
                font-size: xx-large; }

            > .cancel {
                position: absolute;
                right: 0; }
            .avatar-upload {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                .add-photo {
                    margin: auto; } }
            > .add-photo {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center; } } } }
