.responded-workers-page {
  .responded-workers-no-data {
    margin: 0 auto;
    padding-top: 16px;
    font-size: 22px; }
  .responded-workers-top {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    padding: 20px 24px;
    .responded-workers-top-item {
      &:nth-child(2) {
        padding: 8px 0; }
      .responded-workers-top-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #5C6E8C;
        padding-bottom: 8px; }

      .responded-workers-top-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #12324F; } } }
  .custom-table-wrapper {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1) !important;
    margin-top: 20px;
    position: relative;
    width: 100%;
    overflow: auto;
    max-height: calc(100% - 276px);
    min-height: calc(100% - 276px);

    .custom-table {
      margin-top: 0px;
      position: relative;

      .circular-progress {
        margin: 0 auto; }

      .custom-table-header {
        display: flex;
        justify-content: normal;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        height: 76px;
        z-index: 2;
        top: 0;
        position: sticky;
        i {
          cursor: pointer; }
        .devider:not(:last-of-type) {
          border-right: 0.5px solid #DEE7EF; }

        .custom-table-header-item {
          display: block;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
          padding: 15px;
          text-overflow: ellipsis !important;
          background: #FFFFFF;

          .custom-table-header-inner {
            display: flex;
            text-align: left;
            min-width: 100%;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            text-overflow: ellipsis !important;
            overflow: hidden !important;

            .name {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 500;
              color: #12324F;
              flex-basis: 95%; }

            .expands {
              display: flex;
              flex-direction: column;
              flex-basis: 20px;

              .expand {
                cursor: pointer;
                height: 20px;

                &:first-child {
                  margin-bottom: 10px; } }

              .expand-active {
                color: red; } } } } }

      .custom-table-cell {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis; }

      .icon-buttons {
        width: 60px;
        display: flex;
        justify-content: space-between;

        i {
          cursor: pointer; } } } }
  .responded-workers-page-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #12324F; }
  .responded-workers-page-state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 66px;
    .responded-workers-page-state-wrapper {
      display: flex;
      align-items: center;
      .responded-workers-page-state-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 11px; } }
    i {
      cursor: pointer; } }
  .responded-workers-page-btn {
    background-color: red;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    right: -3px;
    //text-transform: uppercase
    //width: 128px
 } }    //height: 40px





