.workers-database-page {
  .workers-database-page-total-count {}

  .workers-database-page-wrapper {

    .workers-database-top {
      display: flex;
      flex-direction: column;
      height: 100px;

      .workers-database-top-total-count {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        color: rgba(18, 50, 79, 1);
        flex-shrink: 0;
        margin-bottom: auto;
        padding-bottom: 6px; }

      .workers-database-top-filters {
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow: auto;

        .filters-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
          margin-left: 10px;
          margin-bottom: 5px;

          .filters-item-text {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: rgba(18, 50, 79, 1);
            padding-right: 10px; }

          i {
            cursor: pointer;
            color: rgba(154, 162, 176, 1); } } } }

    .workers-database-body {
      display: flex; }

    .workers-database-table-wrapper {
      // display: flex
      // flex-direction: row
      // padding-top: 10px
      // justify-content: space-between
      height: calc(100vh - 270px);
      width: 100%;
      min-width: 1078px;
      margin-right: 10px;

      // .workers-database-table
      //   width: 100%
      //   min-width: 1078px
      //   margin-right: 10px

      .ag-header {
        background-color: rgba(255, 255, 255, 1);
        overflow: visible;
        margin-bottom: 10px;
        min-height: 80px !important;

        .ag-header-viewport {
          overflow: visible;
          .ag-header-container {
            height: 80px;

            .ag-header-row {
              min-height: 80px !important;
              transform: none !important;
              overflow: visible;

              .ag-header-cell {
                border-right: 1px solid rgba(225, 225, 231, 1);
                overflow: visible;
                &:not(:last-of-type) {
                  .dialog-menu-filter {
                    left: 1px; } }

                &:last-child {
                  border-right: none; }

                .ag-header-cell-comp-wrapper {
                  overflow: visible; } } } } } }

      .workers-database-header-cell {
        overflow: visible !important;
        position: relative;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .workers-database-header-cell-inner {
          display: flex;
          min-width: 100%;
          height: 100%;
          padding: 0 10px;
          justify-content: center;
          align-items: center;

          .name {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: rgba(18, 50, 79, 1);
            overflow-wrap: break-word;
            white-space: pre-line; }

          .expands {
            display: flex;
            flex-direction: column;
            margin-right: 5px;

            .expand {
              &:first-child {
                margin-bottom: 10px;
                cursor: pointer;
                height: 20px; } }

            .expand-active {
              color: red; } }

          .icons {
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            cursor: pointer;

            .plug {
              width: 24px;
              height: 24px; }

            .filter_alt {
              color: red; }

            .more_horiz {
              cursor: pointer; } } } }

      .ag-cell {
        border-top: none;
        border-right: none;
        border-left: none;

        .ag-cell-wrapper {
 } }          // height: 100%
      .ag-cell {
        display: flex;
        align-items: center !important;
        &[aria-colindex='1'] {
          justify-content: center; }
        &[aria-colindex='3'] {
          justify-content: flex-start !important;
          text-align: left; } }
      .ag-row {
        background: white;
        box-shadow: 1px 0px 0px 1px rgba(225, 225, 231, 1); }

      .checkbox-cell {
        // display: flex
        // min-width: 100%
        // height: 100%
        // align-items: center
        // justify-content: center
        background: rgb(255, 255, 255);
 }        // border-bottom: 1px solid rgba(225, 225, 231, 1)

      .workers-database-table-cell {
        // display: flex
        // align-items: center
        //min-width: 100%
        // height: 100%
        // min-height: 90px
        padding: 8px 16px;
        // border-bottom: 1px solid rgba(225, 225, 231, 1)
        -webkit-touch-callout: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;

        .workers-database-table-cell-inner {
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;
          width: 100%;
          word-break: break-word; }

        .worker-name {
          color: rgba(18, 50, 79, 1);
          text-decoration: underline;
          cursor: pointer;
          white-space: pre-line;
          line-height: 18px;
          text-align: left; }

        .base-profession {
          //padding: 5px

          .base-profession-layout {
            display: flex;
            flex-direction: column;
            align-content: center;
            text-align: left; }

          .base-profession-item {
            display: flex;
            flex-direction: row;
            padding-bottom: 10px;
            &:last-child {
              padding-bottom: 0; }
            .base-profession-item-text {
              white-space: pre-wrap;
              white-space: -moz-pre-wrap;
              white-space: -o-pre-wrap;
              // word-wrap: break-word
              width: 100%;
 }              // word-break: break-word

            .base-profession-btn {
              cursor: pointer;
              color: rgba(0, 124, 237, 1); } } }

        .work-search-status {
          display: flex;
          flex-direction: row;
          align-items: center;

          .work-search-status-circle {
            height: 12px;
            min-width: 12px;
            border-radius: 50%;
            margin-right: 10px; }

          .work-search-status-text {
            color: rgba(18, 50, 79, 1);
            font-size: 16px;
            font-weight: 400;
            line-height: 19px; } }

        .contact-information {
          display: flex;
          flex-direction: column;

          .contact-information-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:first-child {
              padding-bottom: 10px; }

            i {
              padding-right: 11px;
              color: rgba(92, 110, 140, 1); }

            .contact-information-value {
              color: rgba(18, 50, 79, 1);
              text-overflow: ellipsis;
              overflow: hidden;
              width: 200px; } } } } }

    .workers-database-sidebar {
      .workers-database-sidebar-layout {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        padding: 28px;
        width: 325px;
        height: auto;
        flex: 0 0 325px;
        gap: 28px;

        .sidebar-item {
          // padding-bottom: 33px
          .sidebar-item-err {
            font-size: 15px;
            font-weight: 400;
            color: red;
            padding-top: 8px; }
          &:last-child {
            padding-bottom: 0; } }

        .workers-database-sidebar-total-count {
          font-size: 20px;
          font-weight: 600;
          line-height: 23px;
          color: rgba(18, 50, 79, 1); }

        .workers-database-sidebar-contacts {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;

          .contacts {
            display: flex;
            align-items: center;
            padding-right: 15px;

            .contacts-text {
              color: rgba(154, 162, 176, 1); }

            .contacts-count {
              color: rgba(18, 50, 79, 1); } }

          .contacts-error {
            .contacts-text {
              color: rgba(237, 0, 0, 1); }

            .contacts-count {
              color: rgba(237, 0, 0, 1); } } }

        .workers-database-sidebar-selector {
          .sidebar-selector {
            padding-top: 10px;
            width: 100%;
            background: #FFFFFF;

            .sidebar-selector-option {
              padding: 5px 16px;
              display: flex;
              flex-direction: column;
              cursor: default;

              &:hover {
                background-color: #DEEBFE; }

              .sidebar-selector-option-headline {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.3px;
                color: #12324F;
                padding-bottom: 3px; }

              .sidebar-selector-option-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.3px;
                color: #9AA2B0; } }

            .select__control {
              padding: 3px; }

            .sidebar-selector-link {
              display: flex;
              flex-direction: row;
              padding: 16px;
              align-items: center;
              box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.11);

              i {
                padding-right: 10px;
                color: rgba(18, 50, 79, 1); }

              .sidebar-selector-link-text {
                font-size: 15px;
                font-weight: 400;
                line-height: 19px;
                text-decoration: underline;
                color: rgba(38, 123, 223, 1); } } } }

        .workers-database-sidebar-buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          // padding: 0 17px

          button {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0; } } } } } } }
