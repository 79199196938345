.employee-training-item {
  .employee-training-container {
    background-color: #FFFFFF; }

  .employee-training-top {
    display: flex;
    padding: 24px;
    margin-bottom: 17px;

    .employee-training-avatar {
      img {
        width: 91px;
        height: 91px;
        object-fit: cover; } }

    .employee-training-info {
      display: flex;
      flex-direction: column;
      margin-left: 30px;

      .employee-training-service {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        padding-bottom: 3px;
        color: #12324F; }

      .employee-training-companyName {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #9AA2B0; }
      .employee-training-hz {
        margin-top: auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #12324F; } } }

  .employee-training-body {
    padding: 20px 40px;

    .employee-training-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: #5C6E8C; }

    .employee-training-servicePrice {
      padding: 30px 0;
      font-size: 22px;
      font-weight: 900;
      line-height: 26px;
      color: #12324F; }
    .employee-training-button {}
    .employee-training-ordered {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #5C6E8C;
      padding: 12px 10px; } } }
