body {
  margin: 0; }

* {
  box-sizing: border-box; }

#root {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--mdc-theme-text-primary, #354C62);

  > .app__root {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--mdc-theme-background);
    overflow: hidden;

    > .app-container {
      height: 100%;
      width: 100%;
      display: flex;
      overflow: hidden;

      > .app-content {
        position: relative;
        overflow: auto;
        height: 100%;
        height: inherit;
        width: inherit;
        padding: 1rem;

        > * {
          min-height: 100%;
          position: relative; } } }

    > .mdc-top-app-bar {
      position: relative;

      > .mdc-top-app-bar__row {

        > .mdc-top-app-bar__section {
          padding: 8px 0px; } } }

    > .mdc-top-app-bar--fixed-adjust {
      padding-top: 0px; }

    > .footer {
      position: relative;
      // overflow: auto
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      vertical-align: center;
      background-color: var(--mdc-theme-secondary);
      min-height: 45px; } } }
// padding-block: .4rem

.span-a {
  text-decoration: underline;
  text-underline-position: under;
  color: var(--mdc-theme-text-primary);
  cursor: pointer;

  &.span-a--disabled {
    color: var(--mdc-theme-text-disabled-on-background);
    cursor: default; }

  > a {
    color: inherit; } }

.font-weight {
  font-weight: 600 !important; }

.margin-top {
  margin-top: 1rem; }

.pointer {
  cursor: pointer; }

.circular-progress--center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  position: absolute !important; }

.hidden-input {
  width: 0;
  height: 0;
  overflow: hidden; }

h1, h2, h3 {
  margin: 0; }

.item--text {
  font-weight: 600; }

.avatar-block--contain {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: var(--mdc-theme-background); }

a.link {
  all: unset;
  cursor: pointer; }

.false-mdc-input-field {
  height: 44px;
  border-radius: 4px;
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  padding: 12px 16px;
  font-size: 1rem;
  letter-spacing: 0.009375em;
  border: 1px solid rgba(0, 0, 0, 0.38);
  color: rgba(0, 0, 0, 0.87); }

.test-link {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3rem;
  height: 100%;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.1); }

.module-blocked {
  //opacity: 0.6
  pointer-events: none; }
.module-blocked:after {
  content: "";
  display: block;
  width: 101%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -7px;
  background: transparent;
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0.51), #ffffff);
  //opacity: 0.5
  margin-top: 265px;
  z-index: 2; }

.react-select-component {
  &.react-select-component--invalid {
    > .select__control {
      border-color: var(--mdc-theme-error);

      &:hover {
        border-color: var(--mdc-theme-error); }

      &.select__control--is-focused {
        border-color: var(--mdc-theme-error);
        box-shadow: 0 0 0 1px var(--mdc-theme-error); } } }

  > .select__control {
    min-height: 44px;
    border-color: #9E9E9E;
    outline: none;

    &:hover {
      border-color: #373737; }

    &.select__control--is-focused {
      box-shadow: 0 0 0 1px #373737; }

    > .select__indicators {
      > .select__indicator {
        color: #9E9E9E;
        padding: 11px;

        &:hover {
          color: #373737; } }

      > .select__indicator-separator {
        background-color: #9E9E9E;
        padding-block: 6px; } }

    > .select__value-container {
      padding-inline: 12px;
      font-family: Roboto, sans-serif; } }

  > .select__menu {
    > .select__menu-list {
      > .select__option {
        padding: 11.5px 12px;

        &:hover {
          background-color: #F5F5F5; }

        &.select__option--is-focused {
          background-color: #F5F5F5; }

        &.select__option--is-selected {
          background-color: #E1E1E1;
          color: black; } } } } }

form {
  all: unset; }

.unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.icon-disabled {
  color: var(--mdc-theme-text-disabled-on-background); }

.rmwc-tooltip {
  font-size: 1rem !important; }

.rmwc-tooltip-inner {
  color: var(--mdc-theme-text-primary, #354C62) !important;
  background: white !important;
  border: 3px solid var(--mdc-theme-text-primary, #354C62) !important;
  box-shadow: 3px 3px 2px 3px rgba(0, 0, 0, 0.2); }

.mdc-drawer__content {
  display: flex;
  flex-direction: column; }

::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px; }

::-webkit-scrollbar-thumb {
  background: #80a1d9;
  border: 0px solid #ffffff;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:hover {
  background: #80a1d9; }

::-webkit-scrollbar-thumb:active {
  background: #80a1d9; }

::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 5px; }

::-webkit-scrollbar-track:hover {
  background: #ffffff; }

::-webkit-scrollbar-track:active {
  background: #ffffff; }

::-webkit-scrollbar-corner {
  background: transparent; }

.noSelect {
  -webkit-touch-callout: none /* iOS Safari */;
  -webkit-user-select: none /* Safari */;
  -khtml-user-select: none /* Konqueror HTML */;
  -moz-user-select: none /* Old versions of Firefox */;
  -ms-user-select: none /* Internet Explorer/Edge */;
  user-select: none; }
/* Non-prefixed version, currently
 supported by Chrome, Opera and Firefox */

a {
  text-decoration: none; }

.form-field-title {
  color: #12324F;
  margin-bottom: 8px;
  font-weight: 600; }
