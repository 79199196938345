.option-wrapper {
    padding: 5px 16px;
    display: flex;
    flex-direction: column;
    cursor: default;

    &:hover {
        background-color: #DEEBFE; }

    .option-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: #12324F;
        padding-bottom: 3px; }

    .option-subtitle {
        font-weight: 400;
        font-size: 14px !important;
        line-height: 16px;
        letter-spacing: 0.3px;
        color: #9AA2B0; } }

.sidebar-selector-link {
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.11);
    color: rgba(18, 50, 79, 1);

    i {
        padding-right: 10px;
        color: rgba(18, 50, 79, 1); }

    .sidebar-selector-link-text {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: underline;
        color: rgba(38, 123, 223, 1) !important; } }
