.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    position: fixed;
    width: 150px;
    height: 45px;
    right: 3rem;
    bottom: 2rem;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 3px rgba(53, 76, 98, 0.5);
    background-color: white;
    font-size: 20px;
    z-index: 1;

    .loading-inner {
        color: var(--mdc-theme-text-primary, #354C62);
        display: flex;
        align-items: center; }

    .circular-progress {
        margin-top: 2px;
        color: var(--mdc-theme-text-primary, #354C62);
        font-weight: 600;
        font-size: 20px;
        margin-right: 15px; } }
