.versions-history-list {

  .versions-history-list-top {
    .buttons {
      width: 130px;
      margin-left: auto; } }

  .versions-history-list-body {
    margin-top: 24px;
    .versions-history-list-item {
      background-color: white;
      cursor: pointer;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
      padding: 16px 20px;

      .versions-history-list-item-name {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #12324F;
        overflow: hidden;
        text-overflow: ellipsis; }

      .versions-history-list-item-body {
        display: flex;

        .versions-history-list-item-version {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #5C6E8C;
          margin-right: 10px; }

        .versions-history-list-item-date {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #9AA2B0; } } } } }
